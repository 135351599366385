const _ = require('underscore');
const state = {
  menus: [
    {
      key: "menu-0",
      title: "首页",
      url: "/workbench",
      levels: [],//账号等级
      need_permission: false,
    },
    {
      key: "menu-1",
      title: "商品管理",
      url: "/product",
      levels: [],//账号等级
      children: [
        {
          key: "menu-1-0",
          title: "商品管理",
          url: "/product/manage",
          levels: [],//账号等级
          children: [
            {
              key: "menu-1-0-0",
              title: "库存SKU",
              url: "/product/manage/sku",
              levels: [],//账号等级
            },
            {
              key: "menu-1-0-1",
              title: "组合SKU",
              url: "/product/manage/skus",
              levels: [],//账号等级
            },
            {
              key: "menu-1-0-2",
              title: "在线商品匹配",
              url: "/product/manage/pairs",
              levels: [],//账号等级
            },
            {
              key: "menu-1-0-4",
              title: "跟卖提醒",
              url: "/product/manage/genmai",
              levels: [],//账号等级
            },
          ],
        },
      ],
    },
    {
      key: "menu-2",
      title: "订单管理",
      url: "/orders",
      levels: [],//账号等级
      children: [
        {
          key: "menu-2-0",
          title: "订单管理",
          url: "/orders/manage",
          levels: [],//账号等级
          children: [
            {
              key: "menu-2-0-0",
              title: "全部订单",
              url: "/orders/manage/list",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-1",
              title: "待处理",
              url: "/orders/manage/pending",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-2",
              title: "配货中",
              url: "/orders/manage/distribution",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-5",
              title: "已发货",
              url: "/orders/manage/finished",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-6",
              title: "已作废",
              url: "/orders/manage/cancelled",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-7",
              title: "订单出库",
              url: "/orders/manage/outstock",
              levels: [],//账号等级
            },
            {
              key: "menu-2-0-8",
              title: "手工录单",
              url: "/orders/manage/manualrecord",
              levels: [],//账号等级
            },
          ],
        },
        // {
        //   key: "menu-2-1",
        //   title: "其他操作",
        //   url: "/orders/tools",
        //   levels: [],//账号等级
        //   children: [
        //     {
        //       key: "menu-2-1-0",
        //       title: "补录单号",
        //       url: "/orders/tools/supplementrecord",
        //       levels: [],//账号等级
        //     },
        //   ],
        // },
      ],
    },
    {
      key: "menu-3",
      title: "仓库管理",
      url: "/inventory",
      levels: [],//账号等级
      children: [
        {
          key: "menu-3-0",
          title: "库存管理",
          url: "/inventory/manage",
          levels: [],//账号等级
          children: [
            {
              key: "menu-3-0-0",
              title: "库存查看",
              url: "/inventory/manage/inventoryview",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-3-0-1",
            //   title: "库存盘点",
            //   url: "/inventory/manage/check",
            //   levels: [],//账号等级
            // },
            // {
            //   key: "menu-3-0-2",
            //   title: "库龄查看",
            //   url: "/inventory/manage/inventoryage",
            //   levels: [],//账号等级
            // },
          ],
        },
        {
          key: "menu-3-1",
          title: "发货管理",
          url: "/inventory/deliver",
          levels: [],//账号等级
          children: [
            {
              key: "menu-3-1-0",
              title: "智能补货单",
              url: "/inventory/deliver/replenishment",
              levels: [],//账号等级
            },
            {
              key: "menu-3-1-1",
              title: "发货计划",
              url: "/inventory/deliver/plan",
              levels: [],//账号等级
            },
            {
              key: "menu-3-1-2",
              title: "FBA货件",
              url: "/inventory/deliver/FBA",
              levels: [],//账号等级
            },
            {
              key: "menu-3-1-3",
              title: "FBA追踪",
              url: "/inventory/deliver/fbatrack",
              levels: [],//FBA追踪
            },
            {
              key: "menu-3-1-4",
              title: "海外仓发货",
              url: "/inventory/deliver/oversea",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-3-2",
          title: "仓库管理",
          url: "/inventory/warehouse",
          levels: [],//账号等级
          children: [
            {
              key: "menu-3-2-0",
              title: "仓库列表",
              url: "/inventory/warehouse/list",
              levels: [],//账号等级
            },
            {
              key: "menu-3-2-1",
              title: "海外仓",
              url: "/inventory/warehouse/oversea",
              levels: [],//账号等级
            },
          ],
        },
      ],
    },
    {
      key: "menu-4",
      title: "物流渠道",
      url: "/logistics",
      levels: [],//账号等级
      children: [
        {
          key: "menu-4-0",
          title: "物流渠道管理",
          url: "/logistics/supplier",
          levels: [],//账号等级
          children: [
            {
              key: "menu-4-0-0",
              title: "物流商授权",
              url: "/logistics/supplier/authorize",
              levels: [],//账号等级
            },
            {
              key: "menu-4-0-1",
              title: "物流订单统计",
              url: "/logistics/supplier/orderstatics",
              levels: [],//账号等级
            },
            {
              key: "menu-4-0-2",
              title: "物流运费明细",
              url: "/logistics/supplier/fee",
              levels: [],//账号等级
            },
          ],
        },
      ],
    },
    {
      key: "menu-5",
      title: "采购管理",
      url: "/purchase",
      levels: [],//账号等级
      children: [
        {
          key: "menu-5-0",
          title: "采购管理",
          url: "/purchase/manage",
          levels: [],//账号等级
          children: [
            {
              key: "menu-5-0-0",
              title: "采购计划",
              url: "/purchase/manage/plan",
              levels: [],//账号等级
            },
            {
              key: "menu-5-0-1",
              title: "采购单管理",
              url: "/purchase/manage/order",
              levels: [],//账号等级
            },
            {
              key: "menu-5-0-2",
              title: "采购单入库",
              url: "/purchase/manage/instock",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-5-0-3",
            //   title: "订单缺货匹配",
            //   url: "/purchase/manage/ordermatch",
            //   levels: [],//账号等级
            // },
            {
              key: "menu-5-0-4",
              title: "采购统计",
              url: "/purchase/manage/statics",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-5-1",
          title: "供应商管理",
          url: "/purchase/supplier",
          levels: [],//账号等级
          children: [
            {
              key: "menu-5-1-0",
              title: "供应商管理",
              url: "/purchase/supplier/list",
              levels: [],//供应商管理
            },
            {
              key: "menu-5-1-1",
              title: "1688账号授权",
              url: "/purchase/supplier/1688",
              levels: [],//1688授权
            },
          ],
        },
      ],
    },
    {
      key: "menu-6",
      title: "财务管理",
      url: "/operation",
      levels: [],//账号等级
      children: [
        {
          key: "menu-6-0",
          title: "销售报告",
          url: "/operation/sale",
          levels: [],//账号等级
          children: [
            {
              key: "menu-6-0-0",
              title: "收支明细",
              url: "/operation/sale/income",
              levels: [],//账号等级
            },
            {
              key: "menu-6-0-1",
              title: "收支报表",
              url: "/operation/sale/incomereport",
              levels: [],//账号等级
            },
            {
              key: "menu-6-0-2",
              title: "订单状态报表",
              url: "/operation/sale/orderstate",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-6-1",
          title: "财务报告",
          url: "/operation/finance",
          levels: [],//账号等级
          children: [
            {
              key: "menu-6-1-0",
              title: "利润报表",
              url: "/operation/finance/profit",
              levels: [],//账号等级
            }
          ],
        },
        {
          key: "menu-6-2",
          title: "数据分析",
          url: "/operation/analysis",
          levels: [],//账号等级
          children: [
            {
              key: "menu-6-2-0",
              title: "产品表现",
              url: "/operation/analysis/sales",
              levels: [],//账号等级
            },
            {
              key: "menu-6-2-1",
              title: "店铺表现",
              url: "/operation/analysis/shops",
              levels: [],//账号等级
            },
            {
              key: "menu-6-2-2",
              title: "经营看板",
              url: "/operation/analysis/opdata",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-6-3",
          title: "第三方收款",
          url: "/operation/payee",
          levels: [],//账号等级
          children: [
            {
              key: "menu-6-3-0",
              title: "收款账号管理",
              url: "/operation/payee/account",
              levels: [],//账号等级
            },
            {
              key: "menu-6-3-1",
              title: "交易明细",
              url: "/operation/payee/list",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-6-4",
          title: "供应链服务",
          url: "/operation/supplychain",
          levels: [],//账号等级
          children: [
            {
              key: "menu-6-4-0",
              title: "金融服务",
              url: "/operation/supplychain/finance",
              levels: [],//账号等级
            },
          ],
        }
      ],
    },
    {
      key: "menu-7",
      title: "客服服务",
      url: "/customerservice",
      levels: [],//账号等级
      children: [
        // {
        //   key: "menu-7-0",
        //   title: "客户管理",
        //   url: "/customerservice/customer",
        //   levels: [],//账号等级
        //   children: [
        //     {
        //       key: "menu-7-0-0",
        //       title: "客户信息",
        //       url: "/customerservice/customer/customers",
        //       levels: [],//账号等级
        //     },
        //     {
        //       key: "menu-7-0-1",
        //       title: "客户消息管理",
        //       url: "/customerservice/customer/replymessage",
        //       levels: [],//账号等级
        //     },
        //     {
        //       key: "menu-7-0-2",
        //       title: "消息回复模版",
        //       url: "/customerservice/customer/replytemplate",
        //       levels: [],//账号等级
        //     },
        //   ],
        // },
        {
          key: "menu-7-1",
          title: "Walmart",
          url: "/customerservice/walmart",
          levels: [],//账号等级
          children: [
            {
              key: "menu-7-1-0",
              title: "买家消息",
              url: "/customerservice/walmart/buyermessage",
              levels: [],//账号等级
            },
            {
              key: "menu-7-1-1",
              title: "平台消息",
              url: "/customerservice/walmart/sysmessage",
              levels: [],//账号等级
            },
          ],
        },
      ],
    },
    {
      key: "menu-8",
      title: "店铺管理",
      url: "/shops",
      levels: [],//账号等级
      children: [
        {
          key: "menu-8-0",
          title: "店铺管理",
          url: "/shops/manage",
          levels: [],//账号等级
          children: [
            {
              key: "menu-8-0-0",
              title: "店铺授权",
              url: "/shops/manage/authorize",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-8-0-1",
            //   title: "店铺权限设置",
            //   url: "/shops/manage/power",
            //   levels: [],//账号等级
            // },
          ],
        },
        {
          key: "menu-8-1",
          title: "产品刊登",
          url: "/shops/published",
          levels: [],//账号等级
          children: [
            {
              key: "menu-8-1-0",
              title: "产品采集",
              url: "/shops/published/gather",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-1",
              title: "产品库",
              url: "/shops/published/product",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-2",
              title: "Amazon多站点刊登",
              url: "/shops/published/amazonpublishregion",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-8-1-3",
            //   title: "Aliexpress刊登",
            //   url: "/shops/published/aliexpresspublish",
            //   levels: [],//账号等级
            // },
            {
              key: "menu-8-1-3",
              title: "图片库",
              url: "/shops/published/pictures",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-4",
              title: "侵权词库",
              url: "/shops/published/tortwords",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-5",
              title: "UPC管理",
              url: "/shops/published/upc",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-6",
              title: "商标检索",
              url: "/shops/published/tmsearch",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-7",
              title: "专利检索",
              url: "/shops/published/uspto",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-8",
              title: "Amazon搜索词报告",
              url: "/shops/published/searchterm",
              levels: [],//账号等级
            },
            {
              key: "menu-8-1-9",
              title: "Amazon销售排名",
              url: "/shops/published/bestsellers",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-8-1-9",
            //   title: "Amazon搜索词报表",
            //   url: "/shops/published/searchtermreport",
            //   levels: [],//账号等级
            // },
          ],
        },
        {
          key: "menu-8-2",
          title: "广告管理",
          url: "/shops/advertising",
          levels: [],//账号等级
          children: [
            {
              key: "menu-8-2-0",
              title: "多店铺广告预览",
              url: "/shops/advertising/adPreview",
              levels: [],//账号等级
            },
            {
              key: "menu-8-2-1",
              title: "广告列表",
              url: "/shops/advertising/list",
              levels: [],//账号等级
            },
            {
              key: "menu-8-2-2",
              title: "关键词",
              url: "/shops/advertising/keyword",
              levels: [],//账号等级
            },
            {
              key: "menu-8-2-3",
              title: "SKU",
              url: "/shops/advertising/sku",
              levels: [],//账号等级
            },
            {
              key: "menu-8-2-4",
              title: "竞品采集",
              url: "/shops/advertising/competitors_collect",
              levels: [],//账号等级
            },
            // {
            //   key: "menu-8-2-3",
            //   title: "分析",
            //   url: "/shops/advertising/analyze",
            //   levels: [],//账号等级
            // },
          ],
        },
      ],
    },
    {
      key: "menu-9",
      title: "账户设置",
      url: "/accountsetting",
      levels: [],//账号等级
      children: [
        {
          key: "menu-9-0",
          title: "账户设置",
          url: "/accountsetting/accounts",
          levels: ["master", "staff"],//账号等级
          children: [
            {
              key: "menu-9-0-0",
              title: "员工列表",
              url: "/accountsetting/accounts/list",
              levels: [],//账号等级
            },
            {
              key: "menu-9-0-1",
              title: "角色设置",
              url: "/accountsetting/accounts/roles",
              levels: [],//账号等级
            },
            {
              key: "menu-9-0-2",
              title: "权限设置",
              url: "/accountsetting/accounts/permissions",
              levels: [],//账号等级
              username: "zhengbo",
            },
          ],
        },
        {
          key: "menu-9-1",
          title: "学员管理",
          url: "/accountsetting/trainee",
          levels: [],//账号等级
          children: [
            {
              key: "menu-9-1-0",
              title: "学员账号",
              url: "/accountsetting/trainee/list",
              levels: ["master", "staff"],//账号等级
            },
            {
              key: "menu-9-1-1",
              title: "学员权限",
              url: "/accountsetting/trainee/power",
              levels: [],//账号等级
            },
          ],
        },
        {
          key: "menu-9-2",
          title: "企业信息",
          url: "/accountsetting/org",
          levels: ["master"],
        }
      ],
    },
    {
      key: "menu-10",
      title: "Dev",
      url: "/dev",
      levels: [],
      username: "zhengbo",
      env_OS: 'darwin',
      children: [
        {
          key: "menu-10-0",
          title: "Amazon",
          url: "/dev/amazon",
          levels: [],
          children: [
            {
              key: "menu-10-0-0",
              title: "Feeds API",
              url: "/dev/amazon/feeds",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-1",
              title: "Reports API",
              url: "/dev/amazon/reports",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-2",
              title: "Listings API",
              url: "/dev/amazon/listings",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-3",
              title: "Browse Tree",
              url: "/dev/amazon/browsetree",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-4",
              title: "Listing Form Region",
              url: "/dev/amazon/listingformregion",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-5",
              title: "Listing Publish Region",
              url: "/dev/amazon/listingpublishregion",
              levels: [],//账号等级
            },
            {
              key: "menu-10-0-6",
              title: "Clawler",
              url: "/dev/amazon/clawler",
              levels: [],//账号等级
            },
          ]
        },
        {
          key: "menu-10-1",
          title: "Shopee",
          url: "/dev/shopee",
          levels: [],
          children: [
            {
              key: "menu-10-1-0",
              title: "Media Space",
              url: "/dev/shopee/media",
              levels: [],//账号等级
            },
            {
              key: "menu-10-1-1",
              title: "Listing Form",
              url: "/dev/shopee/listingform",
              levels: [],//账号等级
            },
          ]
        },
        {
          key: "menu-10-2",
          title: "Tools",
          url: "/dev/tools",
          levels: [],//账号等级
        },
        {
          key: "menu-10-3",
          title: "AI",
          url: "/dev/ai",
          levels: [],//账号等级
          children: [
            {
              key: "menu-10-3-0",
              title: "AI Generator",
              url: "/dev/ai/generator",
              levels: [],
            },
            {
              key: "menu-10-3-1",
              title: "AI Chat",
              url: "/dev/ai/chat",
              levels: [],
            },
          ]
        },
      ]
    },
  ],
  pages: [
    {
      key: "page-0",
      title: "刊登",
      url: "/shops/published/amazonpublishform",
      levels: [],//账号等级
    },
    {
      key: "page-1",
      title: "采集",
      url: "/shops/published/gatherform",
      levels: [],//账号等级
    },
    {
      key: "page-2",
      title: "多站点刊登",
      url: "/shops/published/amazonpublishformregion",
      levels: [],//账号等级
    },
    {
      key: "page-3",
      title: "竞品采集",
      url: "/shops/advertising/competitors_form",
      levels: [],//账号等级
    },
    {
      key: "page-4",
      title: "淘宝商品列表",
      url: "/orders/manage/tblist",
      levels: [],//账号等级
    },
  ]
};
//获取菜单路由链
function getMenuChain(menus, url) {
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (menu1.url === url) {
      return { menu1 };
    } else if (menu1.children?.length > 0) {
      for (let j = 0; j < menu1.children.length; j++) {
        let menu2 = menu1.children[j];
        if (menu2.url === url) {
          return { menu1, menu2 };
        } else if (menu2.children?.length > 0) {
          for (let k = 0; k < menu2.children.length; k++) {
            let menu3 = menu2.children[k];
            if (menu3.url === url) {
              return { menu1, menu2, menu3 };
            }
          }
        }
      }
    }
  }
  return {};
}
function checkLevel(levels, level) {
  return !Array.isArray(levels) || levels.length === 0 || levels.indexOf(level) > -1;
}
function checkPermission(url, spermissions) {
  let permissions = spermissions || [];
  for (let i = 0; i < permissions.length; i++) {
    let permission = permissions[i];
    if (permission.indexOf(url) === 0) return true;
  }
  return false;
}
function checkMenuPermission(menu, { username, level, permissions }) {
  if (menu.username) {
    let result = menu.username === username;
    if (menu.env_OS) result = result && (menu.env_OS === process.env.OS);
    return result
  }
  return (level === "master" || menu.need_permission === false || (checkLevel(menu.levels, level) && checkPermission(menu.url, permissions)))
}
function checkPagePermission(page, { username, level }) {
  if (page.username) {
    let result = page.username === username;
    if (page.env_OS) result = result && (page.env_OS === process.env.OS);
    return result
  }
  return (level === "master" || page.need_permission === false || (checkLevel(page.levels, level)))
}
function checkTopMenu(menus, { username, level, permissions }) {
  let result1 = [];
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, level, permissions })) {
      if (menu1.children?.length > 0) {
        let result2 = [];
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, level, permissions })) {
            if (menu2.children?.length > 0) {
              let result3 = [];
              for (let k = 0; k < menu2.children.length; k++) {
                let menu3 = menu2.children[k];
                if (checkMenuPermission(menu3, { username, level, permissions })) {
                  result3.push({ ...menu3 });
                }
              }
              if (result3.length > 0) result2.push({ ...menu2, children: [...result3] });
            } else {
              result2.push({ ...menu2 });
            }
          }
        }
        if (result2.length > 0) result1.push({ ...menu1, children: [...result2] });
      } else {
        result1.push({ ...menu1 });
      }
    }
  }
  return result1;
}
function checkLeftMenu(menus, { username, level, permissions }) {
  let result1 = [];
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, level, permissions })) {
      if (menu1.children?.length > 0) {
        let result2 = [];
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, level, permissions })) {
            result2.push({ ...menu2 });
          }
        }
        if (result2.length > 0) result1.push({ ...menu1, children: [...result2] });
      } else {
        result1.push({ ...menu1 });
      }
    }
  }
  return result1;
}
const getters = {
  getTopMenus: (state, getters, rootState) => () => {
    // console.time("getTopMenus");
    let menus = checkTopMenu(state.menus, rootState.user);
    // console.timeEnd("getTopMenus");
    return menus;
  },
  getMenuChain: (state) => (url) => {
    // console.time("getMenuChain");
    let chain = getMenuChain(state.menus, url);
    // console.timeEnd("getMenuChain");
    return chain;
  },
  getLeftMenus: (state, getters, rootState) => (key) => {
    // console.time("getLeftMenus");
    let menus = _.findWhere(state.menus, { key })?.children;
    if (menus && menus.length > 0) {
      // console.timeEnd("getLeftMenus");
      return checkLeftMenu(menus, rootState.user);
    }
    // console.timeEnd("getLeftMenus");
    return [];
  },
};
const mutations = {};
function checkMenu(menus, pages, url, { username, level, permissions }) {
  let default_url = null;
  for (let i = 0; i < menus.length; i++) {
    let menu1 = menus[i];
    if (checkMenuPermission(menu1, { username, level, permissions })) {
      // console.log('checkMenu-menu1', url, _.pick(menu1, ['key', 'title', 'url']), permissions);
      if (menu1.url === url) return true;
      if (menu1.children?.length > 0) {
        for (let j = 0; j < menu1.children.length; j++) {
          let menu2 = menu1.children[j];
          if (checkMenuPermission(menu2, { username, level, permissions })) {
            // console.log('checkMenu-menu2', url, _.pick(menu2, ['key', 'title', 'url']), permissions);
            if (menu2.url === url) return true;
            if (menu2.children?.length > 0) {
              for (let k = 0; k < menu2.children.length; k++) {
                let menu3 = menu2.children[k];
                if (checkMenuPermission(menu3, { username, level, permissions })) {
                  // console.log('checkMenu-menu3', url, _.pick(menu3, ['key', 'title', 'url']), permissions);
                  if (menu3.url === url) return true;
                  if (checkUrlPermission(menu3.url, url)) {
                    if (!default_url) default_url = menu3.url;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < pages.length; i++) {
    let page = pages[i];
    if (checkPagePermission(page, { username, level })) {
      if (page.url === url) return true;
    }
  }
  if (default_url) return default_url;
  return false;
}
function checkUrlPermission(url1, url2) {
  let url1_list = url1.split('/').filter(n => !!n);
  let url2_list = url2.split('/').filter(n => !!n);
  return url1_list[0] === url2_list[0];
}
const actions = {
  checkMenu({ state, commit, rootState }, url) {
    return checkMenu(state.menus, state.pages, url, rootState.user);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
