<template>
  <a-row :gutter="16">
    <a-col :span="3">
      <a-form-item label="报告范围" name="reportPeriod">
        <a-select v-model:value="queryForm.reportPeriod" :options="reportPeriods" style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="3">
      <a-form-item label="排序" name="sort">
        <a-select v-model:value="queryForm.sort" :options="sorts" style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="4">
      <a-form-item label="选择日期" name="reportDay">
        <a-date-picker v-model:value="queryForm.reportDay" :locale="locale" value-format="YYYY-MM-DD"
          style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="4">
      <a-form-item label="关键词" name="searchTerm">
        <a-input v-model:value="queryForm.searchTerm" placeholder="关键词" style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="2">
      <a-button type="primary" @click="getData"> 应用 </a-button>
    </a-col>
    <!-- <a-col :span="12">
      <a-button v-if="report" type="link" @click="downloadReport" :loading="downloading">
        {{ report.originalname }}
        <DownloadOutlined />
      </a-button>
    </a-col> -->
  </a-row>
  <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => ``, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #title>
      <a-space>
        <a-button v-if="username === 'zhengbo'" class="btn-error" @click="showReports" :loading="showing">
          查看报告
        </a-button>
        <!-- <a-button v-if="username === 'zhengbo'" class="btn-orange" @click="showCombines" :loading="showing">
          合并报告
        </a-button> -->
      </a-space>
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'RankData'">
        <p>活跃天数</p>
        <p>连续增长</p>
        <p>当天排名</p>
        <p>排名增长</p>
      </template>
      <template v-if="column.dataIndex === 'clickShare'">
        <p>点击率</p>
        <p>转化率</p>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare1'">
        <p>份额</p>
        <p>转化率</p>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare2'">
        <p>份额</p>
        <p>转化率</p>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare3'">
        <p>份额</p>
        <p>转化率</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'searchTerm'">
        <a-tooltip>
          <template #title> {{ record.searchTerm }} </template>
          <div class="in1line"> {{ record.searchTerm }} </div>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'RankData'">
        <p>{{ record.ActiveDays }}</p>
        <p>{{ record.Continuous }}</p>
        <p>{{ record.searchFrequencyRank }}</p>
        <p>{{ record.VarietyRank }}</p>
      </template>
      <template v-if="column.dataIndex === 'clickShare'">
        <p>{{ record.clickShare }}%</p>
        <p>{{ record.conversionShare }}%</p>
      </template>
      <template v-if="column.dataIndex === 'brand'">
        <p>1：{{ record.brand1 }}</p>
        <p>2：{{ record.brand2 }}</p>
        <p>3：{{ record.brand3 }}</p>
      </template>
      <template v-if="column.dataIndex === 'productType'">
        <p>1：{{ record.productType1 }}</p>
        <p>2：{{ record.productType2 }}</p>
        <p>3：{{ record.productType3 }}</p>
      </template>
      <template v-if="column.dataIndex === 'image1'">
        <a-image width="100%" height="100px" :src="record.image1" style="object-fit: contain;height:100%;">
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'clickedItemName1'">
        <a-tooltip>
          <template #title> {{ record.clickedItemName1 }} </template>
          <div class="in1line"> <a :href="`https://www.amazon.com/dp/${record.clickedAsin1}`" target="_blank">{{
            record.clickedItemName1 }}</a> </div>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare1'">
        <p> {{ record.clickShare1 }}%</p>
        <p> {{ record.conversionShare1 }}%</p>
      </template>
      <template v-if="column.dataIndex === 'image2'">
        <a-image width="100%" height="100px" :src="record.image2" style="object-fit: contain;height:100%;">
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'clickedItemName2'">
        <a-tooltip>
          <template #title> {{ record.clickedItemName2 }} </template>
          <div class="in1line"> <a :href="`https://www.amazon.com/dp/${record.clickedAsin2}`" target="_blank">{{
            record.clickedItemName2 }}</a> </div>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare2'">
        <p> {{ record.clickShare2 }}%</p>
        <p> {{ record.conversionShare2 }}%</p>
      </template>
      <template v-if="column.dataIndex === 'image3'">
        <a-image width="100%" height="100px" :src="record.image3" style="object-fit: contain;height:100%;">
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'clickedItemName3'">
        <a-tooltip>
          <template #title> {{ record.clickedItemName3 }} </template>
          <div class="in1line"> <a :href="`https://www.amazon.com/dp/${record.clickedAsin3}`" target="_blank">{{
            record.clickedItemName3 }}</a> </div>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'AsinClickShare3'">
        <p> {{ record.clickShare3 }}%</p>
        <p> {{ record.conversionShare3 }}%</p>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="reportModal" title="搜索词报告" width="960px" :footer="null">
    <a-table size="small" bordered :columns="rcolumns" :data-source="reports" :loading="showing" row-key="_id"
      :row-selection="{
        selectedRowKeys,
        onChange: (v) => { selectedRowKeys = v },
        getCheckboxProps: (record) => ({ disabled: record.state !== 'success' })
      }"
      :pagination="{ current: report_page, pageSize: report_limit, total: report_count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => ``, }"
      @change="(pagination) => { report_page = pagination.current; report_limit = pagination.pageSize; }">
      <template #title>
        <a-row :gutter="8">
          <a-col :span="3">
            <a-form-item name="reportPeriod">
              <a-select v-model:value="reportForm.reportPeriod" :options="reportPeriods" placeholder="报告范围"
                style="width:100%;" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item name="reportDay">
              <a-date-picker v-model:value="reportForm.reportDay" :locale="locale" value-format="YYYY-MM-DD"
                placeholder="选择日期" style="width:100%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="shopid">
              <a-select v-model:value="reportForm.shopid" :options="shops" placeholder="搜索店铺" style="width:100%;"
                show-search :filter-option="false" :default-active-first-option="false" :show-arrow="false"
                :not-found-content="null" @search="searchShop" @change="changeShop"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-space>
              <a-button type="primary" @click="addReport"> 创建 </a-button>
              <a-button type="primary" @click="showReports"> 刷新 </a-button>
              <!-- <a-button type="danger" @click="combineReports" :loading="combining"
                :disabled="selectedRowKeys.length === 0"> 合并
              </a-button> -->
            </a-space>
          </a-col>
        </a-row>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'reportPeriod'">
          {{ getperiod(record.reportPeriod) }}
        </template>
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.clear_state === 'processing'" color="processing">清理中</a-tag>
          <a-tag v-else-if="record.clear_state === 'success'" color="error">已清理</a-tag>
          <a-tag v-else-if="record.state === 'pending'" color="default">排队中</a-tag>
          <a-tag v-else-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-else-if="record.state === 'saving'" color="orange">正在生成</a-tag>
          <a-tag v-else-if="record.state === 'success'" color="success">成功</a-tag>
          <a-tag v-else-if="record.state === 'fail'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === 'fail'" href="javascript:;" class="text-error" @click="resetReport(record._id)">
              重试
            </a>
            <a v-if="env_DEV === 'yjl' && record.state === 'success' && !record.clear_state" href="javascript:;"
              class="text-error" @click="clearReport(record._id)">
              清理
            </a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="combineModal" title="搜索词合并报告" width="960px" :footer="null">
    <a-table bordered :columns="ccolumns" :data-source="combines" :loading="showing" size="small">
      <template #title>
        <a-space>
          <a-button type="primary" @click="showCombines"> 刷新 </a-button>
        </a-space>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 'pending'" color="default">排队中</a-tag>
          <a-tag v-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.state === 'success'" color="success">成功</a-tag>
          <a-tag v-if="record.state === 'fail'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === 'fail'" href="javascript:;" class="text-error" @click="resetCombine(record._id)">
              重试
            </a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownloadOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useStore } from "vuex";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  { title: '搜索词', dataIndex: 'searchTerm', width: 200, fixed: 'left' },
  { title: '活跃天数/连续增长/当前排名/增长', dataIndex: 'RankData', width: 100 },
  { title: '点击率/转化率', dataIndex: 'clickShare', width: 80 },
  { title: '品牌排名', dataIndex: 'brand', width: 150 },
  { title: '类别排名', dataIndex: 'productType', width: 150 },
  {
    title: '商品一',
    children: [
      { title: '缩略图', dataIndex: 'image1', width: 100 },
      { title: '商品名称', dataIndex: 'clickedItemName1', width: 400 },
      { title: '份额/转化率', dataIndex: 'AsinClickShare1', width: 100 },
    ]
  },
  {
    title: '商品二',
    children: [
      { title: '缩略图', dataIndex: 'image2', width: 100 },
      { title: '商品名称', dataIndex: 'clickedItemName2', width: 400 },
      { title: '份额/转化率', dataIndex: 'AsinClickShare2', width: 100 },
    ]
  },
  {
    title: '商品三',
    children: [
      { title: '缩略图', dataIndex: 'image3', width: 100 },
      { title: '商品名称', dataIndex: 'clickedItemName3', width: 400 },
      { title: '份额/转化率', dataIndex: 'AsinClickShare3', width: 100 },
    ]
  },
  { title: '报告日期', dataIndex: 'reportday', width: 100, fixed: 'right' },
];
const rcolumns = [
  { title: '报告范围', dataIndex: 'reportPeriod', width: 100, },
  { title: '开始日期', dataIndex: 'dataStartTime', width: 100, },
  { title: '结束日期', dataIndex: 'dataEndTime', width: 100, },
  { title: '请求日期', dataIndex: 'req_time', width: 100, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100 },
];
const ccolumns = [
  { title: '开始日期', dataIndex: 'start', width: 100, },
  { title: '结束日期', dataIndex: 'end', width: 100, },
  { title: '请求日期', dataIndex: 'req_time', width: 100, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100 },
];
const { proxy } = getCurrentInstance();
const store = useStore();
const queryForm = ref({
  reportPeriod: "DAY",
  sort: 0,
  reportDay: undefined,
  searchTerm: '',
});
const reportPeriods = ref([
  { value: "DAY", label: "每日" }
]);
const sorts = ref([
  { value: 0, label: "连续增长天数" },
  { value: 1, label: "上榜天数" },
  { value: 2, label: "增长排名" },
  { value: 3, label: "搜索排名" },
  { value: 4, label: "前三点击率" },
  { value: 5, label: "前三转化率" }
]);
const page = ref(1);
const limit = ref(25);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const report = ref(null);
const downloading = ref(false);
const saving = ref(false);
const showing = ref(false);
const reports = ref([]);
const report_page = ref(1);
const report_limit = ref(10);
const report_length = ref(0);
const report_count = ref(0);
const reportModal = ref(false);
const reportForm = ref({
  reportPeriod: "DAY",
  reportDay: undefined,
  shopid: undefined,
});
const combining = ref(false);
const combines = ref([]);
const combineModal = ref(false);
const selectedRowKeys = ref([]);
const env_DEV = ref(process.env.DEV);

const username = computed(() => store.state.user.username);
const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => report_page.value, () => {
  showReports();
});
watch(() => report_limit.value, () => {
  if (report_page.value !== 1) report_page.value = 1;
  else showReports();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/searchterm/results", { page: page.value, limit: limit.value, reportDay: queryForm.value.reportDay, sort: queryForm.value.sort, searchTerm: queryForm.value.searchTerm }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
      report.value = res.data.report;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const downloadReport = () => {
  downloading.value = true;
  proxy.$utils.download(report.value.remoteurl, report.value.originalname).then(() => {
    downloading.value = false;
  });
}
const showReports = () => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/reports", { page: report_page.value, limit: report_limit.value }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      reports.value = _.map(res.data.list, n => {
        return {
          ...n,
          req_time: proxy.$utils.dateFormat(n.update_time || n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      report_length.value = res.data.length;
      report_count.value = res.data.count;
      if (report_length.value > 0 && report_page.value > report_length.value) report_page.value = 1;
      reportModal.value = true;
      selectedRowKeys.value = [];
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const addReport = () => {
  try {
    if (!reportForm.value.reportPeriod) return message.error("报告范围不能为空");
    if (!reportForm.value.reportDay) return message.error("报告日期不能为空");
    if (!reportForm.value.shopid) return message.error("店铺不能为空");
    saving.value = true;
    proxy.$api.doAPI("/searchterm/addreport", { reportForm: reportForm.value }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        showReports();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
    message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
  }
}
const resetReport = (_id) => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/resetreport", { _id }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      showReports();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const clearReport = (_id) => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/clearreport", { _id }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      showReports();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const combineReports = () => {
  if (selectedRowKeys.value.length === 0) return message.error("未选择报告");
  combining.value = true;
  proxy.$api.doAPI("/searchterm/combinereport", { ids: selectedRowKeys.value }).then((res) => {
    combining.value = false;
    if (res.code === 0) {
      reportModal.value = false;
      showCombines();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    combining.value = false;
    console.error(err);
  });
}
const showCombines = () => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/combines").then((res) => {
    showing.value = false;
    if (res.code === 0) {
      combines.value = _.map(res.data.list, n => {
        let states = _.uniq([
          n.state1,
          n.state2,
          n.state3,
          n.state4,
          n.state5,
          n.state6,
          n.state7,
        ]);
        if (states.length === 1) n.state = states[0];
        else {
          if (states.indexOf('processing') > -1) n.state = 'processing';
          else if (states.indexOf('fail') > -1) n.state = 'fail';
        }
        return {
          ...n,
          req_time: proxy.$utils.dateFormat(n.update_time || n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      combineModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const resetCombine = (_id) => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/resetcombine", { _id }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      showCombines();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const getperiod = (value) => {
  return _.findWhere(reportPeriods.value, { value })?.label;
}
let timeout;
let currentValue = '';
const shops = ref([]);
const shopname = ref();
function getShops(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function fake() {
    proxy.$api.doAPI("/searchterm/shops", { value }).then((res) => {
      if (res.code === 0) {
        if (currentValue === value) {
          callback(res.data.list);
        }
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      console.error(err);
    });
  }
  timeout = setTimeout(fake, 300);
}
const searchShop = val => {
  getShops(val, d => shops.value = d);
};
const changeShop = val => {
  shopname.value = val;
  getShops(val, d => shops.value = d);
};
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.text-warning {
  color: #e6a23c !important;
}
</style>
