<template>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">来自平台：</span> </a-col>
      <a-col>
        <a-tag v-for="item in platforms" :key="item.value"
          :color="queryForm.platform === item.value ? (item.color || 'processing') : 'default'" class="click"
          @click="changePlatform(item.value)"> {{ item.label }} </a-tag>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">订单类型：</span> </a-col>
      <a-col>
        <div>
          <a-tag :color="queryForm.ordertype === 0 ? 'processing' : 'default'" @click="changeOrdertype(0)"
            class="click">自发货</a-tag>
          <a-tag :color="queryForm.ordertype === 1 ? 'processing' : 'default'" @click="changeOrdertype(1)"
            class="click">FBA</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">商品检索：</span> </a-col>
      <a-col>
        <a-tag :color="queryForm.skumatch_status === 0 ? 'processing' : 'default'" @click="change_skumatch_status(0)"
          class="click">全部</a-tag>
        <a-tag :color="queryForm.skumatch_status === 1 ? 'success' : 'default'" @click="change_skumatch_status(1)"
          class="click">已匹配</a-tag>
        <a-tag :color="queryForm.skumatch_status === 2 ? 'error' : 'default'" @click="change_skumatch_status(2)"
          class="click">未匹配</a-tag>
      </a-col>
      <a-col> <span style="width:75px;">标发状态：</span> </a-col>
      <a-col>
        <a-tag :color="queryForm.post_fulfillment_status === 0 ? 'processing' : 'default'"
          @click="change_post_fulfillment_status(0)" class="click">全部</a-tag>
        <a-tag :color="queryForm.post_fulfillment_status === 1 ? 'warning' : 'default'"
          @click="change_post_fulfillment_status(1)" class="click">未标发</a-tag>
        <a-tag :color="queryForm.post_fulfillment_status === 2 ? 'processing' : 'default'"
          @click="change_post_fulfillment_status(2)" class="click">标发中</a-tag>
        <a-tag :color="queryForm.post_fulfillment_status === 3 ? 'success' : 'default'"
          @click="change_post_fulfillment_status(3)" class="click">标发成功</a-tag>
        <a-tag :color="queryForm.post_fulfillment_status === 4 ? 'error' : 'default'"
          @click="change_post_fulfillment_status(4)" class="click">标发失败</a-tag>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">所在店铺：</span> </a-col>
      <a-col>
        <a-select v-model:value="queryForm.shopid" placeholder="请选择店铺" mode="tags" :options="shops"
          style="width: 1000px" @change="(v) => changeShop(v)"></a-select>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-row :gutter="8" style="width:100%;">
      <a-col :span="4">
        <a-textarea v-model:value="queryForm.orderids" placeholder="输入订单号搜索,支持多行输入"
          :auto-size="{ minRows: 1, maxRows: 3 }" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-textarea v-model:value="queryForm.logisticsnos" placeholder="输入物流单号搜索,支持多行输入"
          :auto-size="{ minRows: 1, maxRows: 3 }" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-input v-model:value="queryForm.skus" placeholder="输入库存SKU搜索" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-input v-model:value="queryForm.skuname" placeholder="输入库存SKU名称搜索" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-range-picker v-model:value="queryForm.daterange" :locale="locale" value-format="YYYY-MM-DD" />
      </a-col>
      <a-col :span="4">
        <a-button type="primary" @click="getData">搜索</a-button>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button class="btn-warning" @click="bulkintercept" :loading="intercepting">
        批量拦截订单
      </a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item key="shipped">标记已发货</a-menu-item>
            <a-menu-item key="virtualship">批量回传平台发货</a-menu-item>
            <a-menu-item key="canceled" class="text-error">批量作废</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary"> 批处理功能
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-button type="primary" @click="bulkchooselogistics" :loading="logistics_choosing">
        物流交运
      </a-button>
      <a-button type="primary" @click="bulkprintorder" :loading="orderprinting">
        打印面单
      </a-button>
      <ZhengBoAssistant />
    </a-space>
    <a-space>
      <!-- <a-button type="primary" @click="getData">同步订单</a-button> -->
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导出订单">
              <a-menu-item key="1">导出勾选订单</a-menu-item>
              <a-menu-item key="2">导出全部订单</a-menu-item>
              <a-menu-item key="3">查看导出任务</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导出订单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'], total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: changeSelectedRows }" v-model:expandedRowKeys="expandedRowKeys"
    size="small">
    <template #title>
      <MinusOutlined v-if="expanded" class="click" style="border:1px solid #ddd;" @click="foldall" />
      <PlusOutlined v-else class="click" style="border:1px solid #ddd;" @click="expandall" />
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>店铺</p>
        <p>订单号</p>
        <p>SKU</p>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>买家</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>订单金额(原始货币)</p>
        <p>收入运费(原始货币)</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p>创建时间</p>
        <p>付款时间</p>
        <p>剩余发货</p>
        <p>交运时间</p>
        <p>打印时间</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'platform'">
        <a-tag v-if="record.platform === 'amz'" color="#FF9F0F">Amazon</a-tag>
        <a-tag v-else-if="record.platform === 'smt'" color="#E62E04">AliExpress</a-tag>
        <a-tag v-else-if="record.platform === 'ozon'" color="#1985FF">Ozon</a-tag>
        <a-tag v-else-if="record.platform === 'tiktok'" color="#010101">TikTok</a-tag>
        <a-tag v-else-if="record.platform === 'walmart'" color="#FFC935">Walmart</a-tag>
        <a-tag v-else-if="record.platform === 'shopee'" color="#FE5622">Shopee</a-tag>
        <a-tag v-else>{{ record.platform }}</a-tag>
      </template>
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>{{ record.shop?.name }}</p>
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.orderid)">复制</a>
          </template>
          <p>{{ record.orderid }}</p>
        </a-tooltip>
        <p class="text-info in1line"> {{ showorderitems(record) }} </p>
        <p>
          <svg-icon v-if="record.has_battery" iconClass="dianchi1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带电池" />
          <svg-icon v-else iconClass="dianchi" :style="{ 'width': '20px', 'height': '20px' }" title="不带电池" />
          <svg-icon v-if="record.has_magnet" iconClass="citie1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带磁" />
          <svg-icon v-else iconClass="citie" :style="{ 'width': '20px', 'height': '20px' }" title="不带磁" />
          <svg-icon v-if="record.is_liquid" iconClass="yeti1" :style="{ 'width': '20px', 'height': '20px' }"
            title="液体" />
          <svg-icon v-else iconClass="yeti" :style="{ 'width': '20px', 'height': '20px' }" title="非液体" />
          <svg-icon v-if="record.is_powder" iconClass="fenchen1" :style="{ 'width': '20px', 'height': '20px' }"
            title="粉末" />
          <svg-icon v-else iconClass="fenchen" :style="{ 'width': '20px', 'height': '20px' }" title="非粉末" />
          <svg-icon v-if="record.items[0]?.product?.is_combine" iconClass="zuhesku1"
            :style="{ 'width': '20px', 'height': '20px' }" :title="record.items[0]?.seller_sku" />
        </p>
        <a v-if="record.skumatch_status === 'fullmatch'" href="javascript:;" @click="showDeclare(record)">
          申报
        </a>
      </template>
      <template v-if="column.dataIndex === 'skumatch_status'">
        <span v-if="record.skumatch_status === 'fullmatch'" class="text-success">
          SKU已配对
        </span>
        <template v-else>
          <a-popconfirm v-if="record.skumatch_time" title="重新匹配SKU" @confirm="rematch(record)">
            <a href="javascript:;" class="text-error underline"> 重新匹配SKU </a>
          </a-popconfirm>
          <span v-else> 正在匹配SKU </span>
        </template>
      </template>
      <template v-if="column.dataIndex === 'raw_order_status'">
        {{ record.raw_order_status || record.order_status }}
      </template>
      <template v-if="column.dataIndex === 'logistics_channel'">
        <template v-if="record.channelbind">
          <a href="javascript:;" class="underline" @click="chooselogistics(record)">
            【{{ record.supplierbind.name }}】{{ record.channelbind.name }}
            {{ record.channelbind.remark ? `（${record.channelbind.remark}）` : '' }}
          </a>
          <div>
            <a-tag v-if="record.logistics_status === 'pending'" color="warning"> 正在获取物流单号 </a-tag>
            <template v-else-if="record.logistics_status === 'success'">
              <div class="d-flex flex-wrap align-center">
                <a-tooltip v-if="record.orderlogisticsitems.length === 2" color="white">
                  <template #title>
                    <a href="javascript:;" @click="doCopy(record.orderlogisticsitems[1].tracking_number)">复制</a>
                  </template>
                  <a-tag color="error" style="text-decoration: line-through;">
                    {{ record.orderlogisticsitems[1].tracking_number }}
                  </a-tag>
                </a-tooltip>
                <a-tooltip color="white">
                  <template #title>
                    <a href="javascript:;" @click="doCopy(record.logistics_tracking_number)">复制</a>
                  </template>
                  <a-tag color="success"> {{ record.logistics_tracking_number }} </a-tag>
                </a-tooltip>
                <a-tooltip title="打印面单">
                  <a href="javascript:;" @click="printorder(record)">
                    <svg-icon iconClass="printer" :style="{ 'width': '20px', 'height': '20px' }" />
                  </a>
                </a-tooltip>
                <a-tooltip title="物流追踪">
                  <a :href="`https://t.17track.net/zh-cn#nums=${record.logistics_tracking_number}`" target="_blank">
                    <svg-icon iconClass="track" :style="{ 'width': '20px', 'height': '20px' }" />
                  </a>
                </a-tooltip>
              </div>
              <div v-if="record.feed">
                <a-tag v-if="record.feed.step === 'SUCCESS'" color="success"> 标发成功 </a-tag>
                <a-tag v-else-if="record.feed.step === 'ERROR'" color="error"> 标发失败 </a-tag>
                <a-tag v-else color="processing"> 标发中 </a-tag>
              </div>
              <div v-else>
                <a-tag color="warning"> 未标发 </a-tag>
              </div>
            </template>
            <div v-else>
              <a-tag color="error"> 物流单号获取失败 </a-tag>
              <span class="text-error"> {{ record.logistics_reason }} </span>
            </div>
          </div>
        </template>
        <a href="javascript:;" v-else class="text-success underline" @click="chooselogistics(record)">选择物流渠道</a>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>{{ record.shipping_country }}</p>
        <p>{{ record.shipping_state }}</p>
        <p>{{ record.shipping_postalcode }}</p>
        <p>{{ record._shipping_name || record.shipping_name }}</p>
        <a href="javascript:;" @click="showShipping(record)">修改</a>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>{{ record.amount }} {{ record.order_currency }}</p>
        <p>{{ record.freight }} {{ record.order_currency }}</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p title="创建时间">{{ record.create_time }}</p>
        <p title="付款时间">{{ record.purchase_time }}</p>
        <div title="剩余发货">
          <template v-if="record.order_status === 'Unshipped'">
            <p v-if="record.ship_timeout" style="font-size:16px;font-weight:bold;color:#ed5466">
              过期：{{ record.ship_time }}
            </p>
            <template v-else>
              <p v-if="record.latest_ship_date" style="font-size:16px;font-weight:bold;color:#91c854">
                最晚发货：{{ record.latest_ship_date }}
              </p>
              <p style="font-size:16px;font-weight:bold;color:#91c854">
                剩余时间：{{ record.ship_time }}
              </p>
            </template>
          </template>
          <template v-else> - </template>
        </div>
        <p title="交运时间">{{ record.delivery_time || '-' }}</p>
        <p title="打印时间">{{ record.print_time || '-' }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <!-- <a-button type="primary" size="small" danger>拆分</a-button> -->
          <a-tooltip>
            <template #title>
              {{ record.remark || '' }}
              <template v-if="record.order_remark || record.shipping_remark">
                <p v-if="record.order_remark">订单备注：{{ record.order_remark }}</p>
                <p v-if="record.shipping_remark">订单发货备注：{{ record.shipping_remark }}</p>
              </template>
              <span v-else>暂无备注</span>
            </template>
            <a-button type="link" size="small" @click="showRemark(record)">备注</a-button>
          </a-tooltip>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="itemcolumns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false"
        size="small">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <div v-if="icolumn.dataIndex === 'cover'" class="text-center" style="width:80px;">
            <a-tooltip v-if="irecord.product?.cover || irecord.shoplisting?.image_url" placement="rightBottom"
              color="#fff" overlayClassName="coverimage" :overlayStyle="{ width: '500px', height: '500px' }">
              <template #title>
                <div style="position: absolute;z-index: 2000; border:3px solid #ddd;">
                  <img :src="irecord.product?.cover || irecord.shoplisting?.image_url"
                    style="width:500px;height:500px;object-fit: contain;position: relative;z-index: -1;" />
                  <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 360px;">
                    <a-tag color="#ff7039">
                      <span style="font-size:18px;cursor:pointer"
                        @click="gettaobaolist(irecord.product?.cover || irecord.shoplisting?.image_url)"> 淘宝
                      </span>
                    </a-tag>
                  </div>
                  <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 420px;">
                    <a-tag color="#ff7039">
                      <span style="font-size:18px;cursor:pointer"
                        @click="getImagesBase64(irecord.product?.cover || irecord.shoplisting?.image_url)"> 1688
                      </span>
                    </a-tag>
                  </div>
                </div>
              </template>
              <div class="image-header">
                <img :src="irecord.product?.cover || irecord.shoplisting?.image_url" style="object-fit: contain;" />
              </div>
            </a-tooltip>
            <div v-else class="image-header">
              <img :src="nosku" style="object-fit: contain;" />
            </div>
          </div>
          <template v-if="icolumn.dataIndex === 'info'">
            <div>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.product?.sku || irecord.seller_sku)">复制</a>
                </template>
                <a href="javascript:;"
                  @click="editProduct(irecord.product?.sku || irecord.seller_sku, irecord.product?.is_combine)">
                  <span v-if="irecord.product?.is_combine" class="text-error">(组合)</span>
                  {{ irecord.product?.sku || irecord.seller_sku }}
                </a>
              </a-tooltip>
              <span class="text-error text-h6">×{{ irecord.quantity_ordered }}</span>
              <a-tooltip v-if="record.amazon_product_url" color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.asin)">复制</a>
                </template>
                <a :href="`${record.amazon_product_url}${irecord.asin}`" target="_blank" class="underline ml-1">
                  {{ irecord.asin }}
                </a>
                <a-tag color="error" v-if="irecord.genmai"> 疑似被跟卖 </a-tag>
              </a-tooltip>
              <a-tooltip v-if="record.ozon_product_url" color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.orderitemid)">复制</a>
                </template>
                <a :href="`${record.ozon_product_url}${irecord.orderitemid}`" target="_blank" class="underline ml-1">
                  {{ irecord.orderitemid }}
                </a>
              </a-tooltip>
            </div>
            <p>{{ irecord.product?.name_cn || irecord.title }}</p>
            <a v-if="irecord.skumatch_status === 'success'" href="javascript:;" class="text-info underline"
              @click="matchsku(record, irecord)">
              更换
            </a>
            <a v-else href="javascript:;" class="text-error underline" @click="matchsku(record, irecord)">
              配对
            </a>
          </template>
          <template v-if="icolumn.dataIndex === 'cinfo'">
            <template v-if="irecord.product?.combine_list?.length > 0">
              <div v-for="item in irecord.product?.combine_list" :key="item.sku" class="d-flex justify-space-between"
                style="border-bottom:1px solid #ececec;">
                <div class="text-center" style="width:80px;">
                  <a-tooltip v-if="item.cover" placement="right" color="#fff"
                    :overlayStyle="{ width: '500px', height: '500px' }">
                    <template #title>
                      <div style="position: absolute;z-index: 2000; border:3px solid #ddd;">
                        <img :src="item.cover"
                          style="width:500px;height:500px;object-fit: contain;position: relative;z-index: -1;" />
                        <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 360px;">
                          <a-tag color="#ff7039">
                            <span style="font-size:18px;cursor:pointer" @click="gettaobaolist(item.cover)"> 淘宝
                            </span>
                          </a-tag>
                        </div>
                        <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 420px;">
                          <a-tag color="#ff7039">
                            <span style="font-size:18px;cursor:pointer" @click="getImagesBase64(item.cover)"> 1688
                            </span>
                          </a-tag>
                        </div>
                      </div>
                    </template>
                    <div class="image-header">
                      <img :src="item.cover" style="object-fit: contain;" />
                    </div>
                  </a-tooltip>
                  <div v-else class="image-header">
                    <img :src="image_placeholder" style="object-fit: contain;" />
                  </div>
                </div>
                <div style="flex:1;">
                  <div>
                    <a-tooltip color="white">
                      <template #title>
                        <a href="javascript:;" @click="doCopy(item.sku)">复制</a>
                      </template>
                      <a href="javascript:;" @click="editProduct(item.sku)">{{ item.sku }}</a>
                    </a-tooltip>
                    <span class="text-error text-h6">×{{ item.quantity * irecord.quantity_ordered }}</span>
                  </div>
                  <p>{{ item.name_cn }}</p>
                </div>
              </div>
            </template>
            <div v-else>-</div>
          </template>
          <template v-if="icolumn.dataIndex === 'purchase'">
            <a-popconfirm v-if="irecord.skumatch_status === 'success' && irecord.purchasequantity === 0"
              title="添加商品到采购计划?" @confirm="addorderitemrequirement(irecord)">
              <a href="javascript:;" class="underline">添加采购计划</a>
            </a-popconfirm>
            <p class="text-error">
              已计划采购数量：<span class="text-h6">{{ irecord.purchasequantity }}</span>
            </p>
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="logisticsModal" title="选择物流渠道" @ok="confirmLogistics" ok-text="确认" cancel-text="取消"
    :confirm-loading="logistics_choosing" :cancel-button-props="{ disabled: logistics_choosing }" :maskClosable="false"
    :closable="!logistics_choosing">
    <a-form ref="logisticsRef" name="logisticsForm" :model="logisticsForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="物流渠道" name="logistics_channel_bind_id" :rules="[{ required: true, message: '请选择物流渠道' }]">
        <a-select v-model:value="logisticsForm.logistics_channel_bind_id" :options="channelbinds" style="width: 100%"
          :disabled="logistics_choosing" show-search :filter-option="$filters.filterOption">
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="skuModal" title="配对SKU" :maskClosable="false" closable width="800px" :footer="null">
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="csku">组合SKU</a-radio-button>
        <a-radio-button value="cname_cn">组合SKU名称</a-radio-button>
        <a-radio-button value="vsku">库存线上SKU</a-radio-button>
        <a-radio-button value="cvsku">组合线上SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'csku'" v-model:value="skuForm.text" placeholder="请输入组合SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cname_cn'" v-model:value="skuForm.text" placeholder="请输入组合SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存线上SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'cvsku'" v-model:value="skuForm.text" placeholder="请输入组合线上SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skucolumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.cover || image_placeholder" :width="50" :height="50"
            style="object-fit: contain;height:100%;" :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'info'">
          <p>{{ record.sku }}<span v-if="record.is_combine" class="text-error">(组合)</span></p>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="confirmMatch(record)">选择</a>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="declareListModal" :title="`申报信息【${declareOrderNo}】`" :maskClosable="false"
    :closable="!declareListLoading" width="1000px" :footer="null">
    <a-table :columns="declarecolumns" bordered :data-source="declareList" :scroll="{ y: 600 }"
      :loading="declareListLoading" :pagination="false" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="editDeclare(record)">编辑</a>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="declareFormModal" :title="`申报信息【${declareForm.sku}】`" :maskClosable="false"
    :closable="!declareFormLoading" width="600px" @cancel="() => declareFormModal = false">
    <a-form ref="declareRef" name="declareForm" :model="declareForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="申报中文名" name="declare_name_cn" :rules="[{ required: true, message: '请输入申报中文名' }]">
        <a-input v-model:value="declareForm.declare_name_cn" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报英文名" name="declare_name_en" :rules="[{ required: true, message: '请输入申报英文名' }]">
        <a-input v-model:value="declareForm.declare_name_en" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报价值" name="declare_amount" :rules="[{ required: true, message: '请输入申报价值' }]">
        <a-input v-model:value="declareForm.declare_amount" type="number" addon-after="USD"
          :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报重量" name="declare_weight" :rules="[{ required: true, message: '请输入申报重量' }]">
        <a-input v-model:value="declareForm.declare_weight" type="number" addon-after="克"
          :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="申报数量" name="declare_quantity" :rules="[{ required: true, message: '请输入申报数量' }]">
        <a-input v-model:value="declareForm.declare_quantity" type="number" :disabled="declareFormLoading" />
      </a-form-item>
      <a-form-item label="报关编码" name="hs_code">
        <a-input v-model:value="declareForm.hs_code" type="number" placeholder="非必填" :disabled="declareFormLoading" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <div>
          总价：
          <span style="color:#ed5466;font-weight:700;">
            {{ declareForm.declare_quantity * declareForm.declare_amount }}
          </span> USD
          总重量：
          <span style="color:#ed5466;font-weight:700;">
            {{ declareForm.declare_quantity * declareForm.declare_weight }}
          </span> g
        </div>
        <div>
          <a-checkbox v-model:checked="declareForm.update_product" :disabled="declareFormLoading"
            style="color:#3f56a1;font-weight:700;">
            同时修改商品申报信息
          </a-checkbox>
          <a-button @click="saveDeclare" type="primary" :loading="declareFormLoading">确认</a-button>
          <a-button @click="() => declareFormModal = false" :disabled="declareFormLoading">关闭</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="shippingModal" title="修改收件信息" @ok="saveShipping" ok-text="确认" cancel-text="取消"
    :confirm-loading="shippingLoading" :cancel-button-props="{ disabled: shippingLoading }" :maskClosable="false"
    :closable="!shippingLoading" width="800px">
    <a-form ref="shippingRef" name="shippingForm" :model="shippingForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="收件人" name="shipping_name" :rules="[{ required: true, message: '请输入收件人' }]">
            <a-input v-model:value="shippingForm.shipping_name" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系电话" name="shipping_phone" :rules="[{ required: true, message: '请输入联系电话' }]">
            <a-input v-model:value="shippingForm.shipping_phone" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系邮箱" name="shipping_email">
            <a-input v-model:value="shippingForm.shipping_email" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="国家/地区" name="shipping_countrycode">
            <a-select v-model:value="shippingForm.shipping_countrycode" :options="shippingCountrys" style="width: 100%"
              :disabled="shippingLoading" show-search :filter-option="$filters.filterOption">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="州/省" name="shipping_state">
            <a-input v-model:value="shippingForm.shipping_state" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="城市" name="shipping_city">
            <a-input v-model:value="shippingForm.shipping_city" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="地区" name="shipping_district">
            <a-input v-model:value="shippingForm.shipping_district" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="邮编" name="shipping_postalcode">
            <a-input v-model:value="shippingForm.shipping_postalcode" :disabled="shippingLoading" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="地址" name="shipping_address"
        :rules="[{ required: true, message: '请输入地址' }]">
        <a-input v-model:value="shippingForm.shipping_address" :disabled="shippingLoading" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="virtualshipModal" title="批量确认" :maskClosable="false" :closable="!virtualShipping"
    @cancel="() => virtualshipModal = false" width="750px">
    <div>
      <a-alert message="注意：操作【回传平台发货】后运单号如果没有物流轨迹，可能会引起平台处罚、冻结等情况。" type="warning" />
      <p class="mt-2 mb-2">
        确定将选择的订单批量回传平台发货吗？(订单个数: <span class="text-error">{{ virtualshipForm.order_ids?.length || 0 }}</span>)
      </p>
      <p>
        注意：部分订单已向平台回传平台发货过，再次同步，可能会将单号再次同步平台，请谨慎操作。
      </p>
      <div class="mt-2">
        <p>使用提示：</p>
        <p>1、人工新增订单、人工导入订单无法回传平台，将被自动过滤</p>
        <p>2、确定执行【回传平台发货】后，将会回传平台关联订单的运单号信息并更新平台订单状态为发货状态</p>
        <p>3、如期望订单在实际发货成功后再次自动回传更新运单号信息，请在确定前勾选【真实发货后同步平台】参数</p>
        <p>4、AMZ考核跟踪物流的及时性，若使用该功能上传空单号或者假单号导致物流轨迹无法被平台追踪而造成一切后果由使用者自行负责。</p>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-checkbox v-model:checked="virtualshipForm.sync_after_logistics" :disabled="virtualShipping">
          真实发货后同步平台
        </a-checkbox>
        <div>
          <a-button @click="virtualshipOrder" type="primary" :loading="virtualShipping">确定</a-button>
          <a-button @click="() => virtualshipModal = false" :disabled="virtualShipping">关闭</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="interceptModal" title="拦截原因" @ok="confirmintercept" ok-text="确认" cancel-text="取消"
    :confirm-loading="intercepting" :cancel-button-props="{ disabled: intercepting }" :maskClosable="false"
    :closable="!intercepting">
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" autocomplete="off">
      <a-form-item label="原因">
        <a-input v-model:value="reason" :disabled="intercepting" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="remarkModal" title="修改备注" :maskClosable="false" :closable="!remarkSaving"
    @cancel="() => remarkModal = false" width="750px">
    <a-tabs v-model:activeKey="remarkForm.remark_type">
      <a-tab-pane key="order_remark" tab="订单备注">
        <a-textarea v-model:value="remarkForm.order_remark" placeholder="订单备注"
          :auto-size="{ minRows: 5, maxRows: 5 }" />
      </a-tab-pane>
      <a-tab-pane key="shipping_remark" tab="订单发货备注">
        <a-textarea v-model:value="remarkForm.shipping_remark" placeholder="订单发货备注"
          :auto-size="{ minRows: 5, maxRows: 5 }" />
      </a-tab-pane>
    </a-tabs>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-button @click="saveRemark" type="primary" :loading="remarkSaving">确定</a-button>
        <a-button @click="() => remarkModal = false" :disabled="remarkSaving">关闭</a-button>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="taskModal" centered :maskClosable="false" :closable="!taskLoading" :footer="null"
    width="750px">
    <template #title>
      <a-space>
        导出任务
        <a-button :loading="taskLoading" @click="showTask">刷新</a-button>
      </a-space>
    </template>
    <a-table :columns="taskcolumns" :data-source="tasklist" :loading="taskLoading" :scroll="{ x: twidth }" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.state === 'success'" color="success">成功</a-tag>
          <a-space v-if="record.state === 'error'">
            <a-tag color="error">失败</a-tag>
            <a-tooltip color="white" v-if="record.error">
              <template #title>
                {{ record.error }}
              </template>
              <a href="javascript:;">原因</a>
            </a-tooltip>
          </a-space>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.state === 'success'" type="link" danger @click="downloadTask(record)">下载</a-button>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, nextTick, toRaw, } from "vue";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import ZhengBoAssistant from "../../../components/ZhengBoAssistant.vue";
import { printBlob } from "../../../zhengbo";
const { proxy } = getCurrentInstance();
const columns = ref([
  { title: "来自平台", dataIndex: "platform", width: 100 },
  { title: "店铺/店长/订单号", dataIndex: "shop_orderid", width: 200 },
  { title: "SKU配对", dataIndex: "skumatch_status", width: 100 },
  { title: "物流渠道", dataIndex: "logistics_channel", width: 200 },
  { title: "买家", dataIndex: "buyer", width: 200 },
  { title: "订单金额", dataIndex: "order_amount", width: 150 },
  { title: "时间", dataIndex: "time", width: 240 },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right" },
]);
const itemcolumns = ref([
  { title: '图片', dataIndex: 'cover', width: 50 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'info', width: 200 },
  { title: '组合SKU编号/数量/中文名称', dataIndex: 'cinfo', width: 200 },
  { title: '采购信息', dataIndex: 'purchase', width: 100 },
]);
const skucolumns = ref([
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 400 },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
]);
const declarecolumns = ref([
  { title: 'SKU', dataIndex: 'sku', width: 180 },
  { title: '申报信息', dataIndex: 'info' },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
])
const taskcolumns = ref([
  { title: '任务编号', dataIndex: 'number', width: 100 },
  { title: '任务状态', dataIndex: 'state', width: 120 },
  { title: '创建时间', dataIndex: 'create_time', width: 120 },
  { title: '操作', dataIndex: 'operation', width: 120 },
])
const logisticsRef = ref();
const declareRef = ref();
const shippingRef = ref();
const queryForm = ref({
  orderids: "",
  logisticsnos: "",
  skus: "",
  skuname: "",
  orderstate: 2,
  ordertype: 0,
  platform: '',
  shopid: [],
  skumatch_status: 0,
  post_fulfillment_status: 0,
  daterange: [undefined, undefined],
});
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const selectedRowKeys = ref([]);
const logistics_choosing = ref(false);
const logisticsModal = ref(false);
const channelbinds = ref([]);
const logisticsForm = ref({});
const intercepting = ref(false);
const interceptModal = ref(false);
const reason = ref("");
const skuModal = ref(false);
const skuForm = ref({ type: "sku", text: "" });
const skupage = ref(1);
const skulimit = ref(100);
const skudata = ref([]);
const skulength = ref(0);
const skucount = ref(0);
const skuloading = ref(false);
const order_id = ref("");
const declareOrderNo = ref("");
const declareList = ref([]);
const declareListModal = ref(false);
const declareListLoading = ref(false);
const declareForm = ref({});
const declareFormModal = ref(false);
const declareFormLoading = ref(false);
const shippingForm = ref({});
const shippingModal = ref(false);
const shippingLoading = ref(false);
const shippingCountrys = ref([]);
const orderprinting = ref(false);
const expandedRowKeys = ref([]);
const expanded = ref(false);
const shops = ref([]);
const platforms = ref([]);
const virtualshipForm = ref({
  order_ids: [],
  sync_after_logistics: true,
});
const virtualshipModal = ref(false);
const virtualShipping = ref(false);
const remarkForm = ref({});
const remarkModal = ref(false);
const remarkSaving = ref(false);
const tasklist = ref([]);
const taskModal = ref(false);
const taskLoading = ref(false);

const width = computed(() => proxy.$utils.twidth(columns.value));
const iwidth = computed(() => proxy.$utils.twidth(itemcolumns.value));
const twidth = computed(() => proxy.$utils.twidth(taskcolumns.value));

watch(() => page.value, () => {
  foldall();
  getData()
});
watch(() => limit.value, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.platform, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.ordertype, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.skumatch_status, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.post_fulfillment_status, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.shopid, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => skupage.value, () => searchSku());
watch(() => skulimit.value, () => {
  if (skupage.value !== 1) skupage.value = 1;
  else searchSku();
});
onActivated(() => {
  getShops();
});
const changePlatform = (item) => {
  queryForm.value.platform = item;
  getShops();
};
const changeOrdertype = (item) => {
  queryForm.value.ordertype = item;
};
const change_skumatch_status = (item) => {
  queryForm.value.skumatch_status = item;
};
const change_post_fulfillment_status = (item) => {
  queryForm.value.post_fulfillment_status = item;
};
const changeShop = (item) => {
  queryForm.value.shopid = item;
};
const getShops = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/product/shops", { platform: queryForm.value.platform, }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      shops.value = res.data.shops;
      platforms.value = [{ label: "全部", value: "" }, ...res.data.platforms];
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getData = () => {
  selectedRowKeys.value = [];
  dataLoading.value = true;
  let params = {
    page: page.value, limit: limit.value, ...queryForm.value
  };
  proxy.$api.doAPI("/order/list", params).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const exportData = (ids) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/order/exportlist", { ids, ...queryForm.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      showTask();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const showTask = () => {
  taskLoading.value = true;
  proxy.$api.doAPI("/order/exporttask").then((res) => {
    taskLoading.value = false;
    if (res.code === 0) {
      tasklist.value = res.data.list;
      taskModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    taskLoading.value = false;
    console.error(err);
  });
}
const downloadTask = (item) => {
  proxy.$utils.download(item.remoteurl, item.originalname);
}
const chooselogistics = (order) => {
  _chooselogistics([order._id], order);
}
const bulkchooselogistics = () => {
  if (selectedRowKeys.value.length === 0) return message.warn("未选择订单");
  _chooselogistics(selectedRowKeys.value);
}
const _chooselogistics = (order_ids, order) => {
  logistics_choosing.value = true;
  proxy.$api.doAPI("/order/chooselogistics").then((res) => {
    logistics_choosing.value = false;
    if (res.code === 0) {
      channelbinds.value = res.data.channelbinds;
      logisticsForm.value = {
        order_ids,
        logistics_channel_bind_id: order?.logistics_channel_bind_id,
      };
      logisticsModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    logistics_choosing.value = false;
    console.error(err);
  });
};
const confirmLogistics = async () => {
  try {
    await logisticsRef.value.validateFields();
    logistics_choosing.value = true;
    proxy.$api.doAPI("/order/confirmlogistics", { logisticsForm: logisticsForm.value }).then((res) => {
      logistics_choosing.value = false;
      getData();
      if (res.code === 0) {
        logisticsModal.value = false;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      logistics_choosing.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
//批量拦截订单
const bulkintercept = () => {
  if (selectedRowKeys.value.length === 0) return message.warn("未选择订单");
  reason.value = "";
  interceptModal.value = true;
}
const confirmintercept = () => {
  if (selectedRowKeys.value.length === 0) return message.warn("未选择订单");
  _intercept(selectedRowKeys.value);
}
const _intercept = (order_ids) => {
  intercepting.value = true;
  proxy.$api.doAPI("/order/intercept", { order_ids, reason: reason.value }).then((res) => {
    intercepting.value = false;
    if (res.code === 0) {
      interceptModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    intercepting.value = false;
    console.error(err);
  });
}
const addorderitemrequirement = (item) => {
  console.log('addorderitemrequirement', item);
  dataLoading.value = true;
  proxy.$api.doAPI("/order/addorderitemrequirement", { orderitem_id: item._id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const rematch = (order) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/order/rematchsku", { order_id: order._id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const matchsku = (order, orderitem) => {
  console.log('matchsku', order, orderitem);
  skuForm.value = { type: "sku", text: "", order_id: order._id, orderitem_id: orderitem._id };
  if (orderitem.skumatch_status === 'success') {
    skuForm.value.text = orderitem.product?.sku;
  } else {
    skuForm.value.type = "vsku";
    skuForm.value.text = orderitem.seller_sku;
  }
  if (orderitem.product?.is_combine) {
    skuForm.value.type = "c" + skuForm.value.type;
  }
  skupage.value = 1;
  skudata.value = [];
  skuModal.value = true;
}
const confirmMatch = (item) => {
  skuloading.value = true;
  proxy.$api.doAPI("/product/matchsku", { skuForm: skuForm.value, productid: item._id, }).then((res) => {
    skuloading.value = false;
    if (res.code === 0) {
      skuModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    skuloading.value = false;
    console.error(err);
  });
}
const searchSku = () => {
  // if (!skuForm.value.text) return message.error("请输入搜索内容");
  skuloading.value = true;
  proxy.$api.doAPI("/product/searchsku", { page: skupage.value, limit: skulimit.value, skuForm: skuForm.value, }).then((res) => {
    skuloading.value = false;
    if (res.code === 0) {
      skudata.value = res.data.list;
      skulength.value = res.data.length;
      skucount.value = res.data.count;
      if (skulength.value > 0 && skupage.value > skulength.value) skupage.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    skuloading.value = false;
    console.error(err);
  });
}
const showDeclare = (item) => {
  order_id.value = item._id;
  declareOrderNo.value = item.orderid;
  _showDeclare();
}
const _showDeclare = () => {
  declareListModal.value = true;
  declareListLoading.value = true;
  proxy.$api.doAPI("/order/showdeclare", { _id: order_id.value }).then((res) => {
    declareListLoading.value = false;
    if (res.code === 0) {
      declareList.value = _.map(res.data.list, n => {
        return {
          ...n,
          info: [
            `${n.declare_name_cn || '--'}`,
            `${n.declare_name_en || '--'}`,
            `$${n.declare_amount || '--'}`,
            `${n.declare_weight || '--'}g`,
            `${n.declare_quantity || '--'}`,
            `${n.hs_code || '--'}`
          ].join('/'),
        }
      });
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    declareListLoading.value = false;
    console.error(err);
  });
}
const editDeclare = (item) => {
  declareForm.value = _.extend({ update_product: false }, item);
  declareFormModal.value = true;
}
const saveDeclare = async () => {
  try {
    await declareRef.value.validateFields();
    declareFormLoading.value = true;
    proxy.$api.doAPI("/order/savedeclare", { declareForm: declareForm.value }).then((res) => {
      declareFormLoading.value = false;
      if (res.code === 0) {
        declareFormModal.value = false;
        _showDeclare();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      declareFormLoading.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const showShipping = (item) => {
  order_id.value = item._id;
  _showShipping();
}
const _showShipping = () => {
  shippingModal.value = true;
  shippingLoading.value = true;
  proxy.$api.doAPI("/order/showshipping", { _id: order_id.value }).then((res) => {
    shippingLoading.value = false;
    if (res.code === 0) {
      shippingForm.value = res.data.shippingForm;
      shippingCountrys.value = _.map(res.data.countrys, n => ({ ...n, label: `[${n.code}] ${n.name} ${n.name_en}`, value: n.code }));
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    shippingLoading.value = false;
    console.error(err);
  });
};
const saveShipping = async () => {
  try {
    await shippingRef.value.validateFields();
    shippingLoading.value = true;
    proxy.$api.doAPI("/order/saveshipping", { _id: order_id.value, shippingForm: shippingForm.value }).then((res) => {
      shippingLoading.value = false;
      if (res.code === 0) {
        shippingModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      shippingLoading.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const handleBulkClick = ({ key }) => {
  if (selectedRowKeys.value.length === 0) return message.warn("未选择订单");
  console.log('handleBulkClick', key);
  switch (key) {
    case "shipped": //标记已发货
    case "canceled": //作废
    case "virtualship": //虚假发货
      if (key === 'virtualship') {
        // 批量确认
        virtualshipForm.value = {
          order_ids: selectedRowKeys.value,
          sync_after_logistics: true,
        };
        virtualshipModal.value = true;
      } else {
        markorder(selectedRowKeys.value, key);
      }
      break;
    case "selectcustomtag": //标记自定义分类

      break;
    case "removecustomtag": //取消自定义分类

      break;
  }
}
const virtualshipOrder = () => {
  virtualShipping.value = true;
  proxy.$api.doAPI("/order/markorder", { ...virtualshipForm.value, status: "virtualship" }).then((res) => {
    virtualShipping.value = false;
    if (res.code === 0) {
      virtualshipModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    virtualShipping.value = false;
    console.error(err);
  });
}
const markorder = (order_ids, status) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/order/markorder", { order_ids, status }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const handleExportClick = ({ key }) => {
  console.log('handleExportClick', key);
  if (key === '1') {//导出勾选订单
    if (selectedRowKeys.value.length === 0) return message.error("未勾选订单");
    exportData(selectedRowKeys.value);
  }
  if (key === '2') {//导出全部页订单
    exportData([]);
  }
  if (key === '3') {//查看导出任务
    showTask();
  }
}
const showorderitems = ({ items }) => {
  return _.reduce(items, (a, b) => a.concat(`${b.seller_sku || b.asin}*${b.quantity_ordered}`), []).join(';')
}
const changeSelectedRows = (keys) => {
  let list = _.filter(data.value, n => keys.indexOf(n._id) > -1);
  selectedRowKeys.value = _.pluck(list, "_id");
  console.log(toRaw(selectedRowKeys.value));
}
const printorder = (item) => {
  _printorder([item._id]);
}
const bulkprintorder = () => {
  if (selectedRowKeys.value.length === 0) return message.error("未选择订单");
  _printorder(selectedRowKeys.value);
}
const _printorder = (order_ids) => {
  orderprinting.value = true;
  proxy.$api.doAPI("/order/printorder", { order_ids }).then((res) => {
    orderprinting.value = false;
    if (res.code === 0) {
      let urls = res.data.urls;
      proxy.$utils.mergePDF(urls).then((blob) => {
        printBlob(blob);
      });
      // selectedRowKeys.value = [];
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    orderprinting.value = false;
    console.error(err);
  });
}
const expandall = () => {
  expandedRowKeys.value = _.pluck(data.value, "_id");
  expanded.value = true;
}
const foldall = () => {
  expandedRowKeys.value = [];
  expanded.value = false;
}
const gettaobaolist = (url) => {
  proxy.$utils.downloadBrowser({ href: `/orders/manage/tblist?url=${url}` });
}
const getImagesBase64 = (url) => {
  message.loading("", 0);
  let image = new Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = url + '?tamp=' + (new Date()).valueOf();
  image.onload = () => {
    let canvas = document.createElement("canvas");
    let width = image.width > 500 ? 500 : image.width;
    let height = image.height > 500 ? 500 : image.height;
    canvas.width = width;
    canvas.height = height;
    let context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, width, height);
    let dataURL = canvas.toDataURL("image/png");
    dataURL = dataURL.split(",")[1];
    proxy.$api.doAPI("/ae/uploadImage", { base64File: dataURL }).then((res) => {
      if (res.code === 0) {
        message.destroy();
        let { success, code, result, message: msg } = res.data.data.result;
        if (code === '200') {
          if (result) {
            window.open(`https://ifenxiao.1688.com/hostList.html?tab=imageSearch&imageId=${result}`, '_blank')
          }
        } else {
          message.error(msg);
        }
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      message.destroy();
      console.error(err);
    });
  }
}
const editProduct = (sku, is_combine) => {
  if (is_combine) {
    proxy.$utils.downloadBrowser({ href: `/product/manage/skus?sku=${sku}` });
  } else {
    proxy.$utils.downloadBrowser({ href: `/product/manage/sku?sku=${sku}` });
  }
}
const showRemark = (item) => {
  remarkForm.value = {
    _id: item._id,
    order_remark: item.order_remark || '',
    shipping_remark: item.shipping_remark || '',
    remark_type: 'order_remark',
  };
  remarkModal.value = true;
}
const saveRemark = () => {
  remarkSaving.value = true;
  proxy.$api.doAPI("/order/saveremark", remarkForm.value).then((res) => {
    remarkSaving.value = false;
    if (res.code === 0) {
      message.success("保存成功");
      remarkModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    remarkSaving.value = false;
    console.error(err);
  });
}

const doCopy = proxy.$utils.doCopy;
const image_placeholder = ref(proxy.$constant.image_placeholder);
const nosku = ref(proxy.$constant.nosku);
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
