<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">采购日期：</span>
    <a-range-picker v-model:value="daterange" :locale="locale" value-format="YYYY-MM-DD" />
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="商品SKU/名称查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleAddOrder">
            <a-menu-item key="1">1688在线下单</a-menu-item>
            <a-menu-item key="2">线下采购单</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary" :loading="initing">
          添加采购单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: height }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    v-model:expandedRowKeys="expandedRowKeys" size="small" :defaultExpandAllRows="true">
    <template #title>
      <MinusOutlined v-if="expanded" class="click" style="border:1px solid #ddd;" @click="foldall" />
      <PlusOutlined v-else class="click" style="border:1px solid #ddd;" @click="expandall" />
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'number_createtime'">
        <p>采购平台</p>
        <p>采购单号</p>
        <p>下单时间</p>
      </template>
      <template v-if="column.dataIndex === 'warehouse_supplier'">
        <p>仓库</p>
        <p>供应商</p>
      </template>
      <template v-if="column.dataIndex === 'quantity_amount'">
        <p>总数量</p>
        <p>总金额</p>
      </template>
      <template v-if="column.dataIndex === 'orderinfo'">
        <p>采购账号</p>
        <p>交易方式</p>
        <p>订单号</p>
        <p>订单状态</p>
      </template>
      <template v-if="column.dataIndex === 'purchase_time_by'">
        <p>采购时间</p>
        <p>采购人</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'number_createtime'">
        <p> {{ record.platform }} </p>
        <p> {{ record.number }} </p>
        <p> {{ record.create_time }} </p>
      </template>
      <template v-if="column.dataIndex === 'warehouse_supplier'">
        <div>
          <p v-if="record.warehouse"> {{ record.warehouse.name }} </p>
          <a v-else href="javascript:;" @click="choosewarehouse(record)">
            选择仓库
          </a>
        </div>
        <a v-if="record.product_supplier?.url" :href="record.product_supplier.url" target="_blank">
          {{ record.product_supplier?.name }}
          <LinkOutlined />
        </a>
        <p v-else>
          {{ record.product_supplier?.name || '-' }}
          <SearchOutlined v-if="record.product_supplier?.name" class="text-info" @click="searchSupplier2(record)" />
        </p>
      </template>
      <template v-if="column.dataIndex === 'quantity_amount'">
        <p> {{ record.quantity }} </p>
        <p> {{ record.amount }} </p>
      </template>
      <template v-if="column.dataIndex === 'orderinfo'">
        <p> {{ record.buyer || '-' }}</p>
        <p>{{ record.baseInfo?.tradeTypeDesc || '-' }}</p>
        <p v-if="record.orderId">
          <a v-if="record.platform === '1688'"
            :href="`https://trade.1688.com/order/order_detail.htm?orderId=${record.orderId}`" target="_blank">
            {{ record.orderId }}
          </a>
          <span v-else class="text-info">{{ record.orderId }}</span>
          <ReloadOutlined v-if="record.platform === '1688' && record.state !== 'canceled'"
            class="click text-warning ml-1" @click="refreshOrder(record._id)" />
        </p>
        <p v-else>-</p>
        <p :class="{
          'text-info': record.orderStatus === 'waitbuyerpay',
          'text-warning': record.orderStatus === 'waitsellersend',
          'text-purple': record.orderStatus === 'waitbuyerreceive',
          'text-success': record.orderStatus === 'confirm_goods' || record.orderStatus === 'success',
          'text-error': record.orderStatus === 'cancel' || record.orderStatus === 'terminated'
        }">
          {{ record.orderStatusStr || '-' }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'express_list'">
        <template v-if="record.express_list?.length > 0">
          <p v-for="express_item in record.express_list" :key="express_item">
            <a :href="`https://m.kuaidi100.com/result.jsp?nu=${express_item.express_no}`" target="_blank">
              {{ express_item.express_supplier }}:{{ express_item.express_no }}
            </a>
            <a-tooltip>
              <template #title> 复制运单号 </template>
              <CopyOutlined class="click text-info ml-1" @click="doCopy(express_item.express_no)" />
            </a-tooltip>
            <a-tooltip>
              <template #title> 查看轨迹 </template>
              <EyeOutlined class="click text-info ml-1" @click="showLogisticsTrackInfo(express_item._id)" />
            </a-tooltip>
          </p>
        </template>
        <a v-if="record.state === 'pending' || record.state === 'purchase'" href="javascript:;"
          @click="addExpress(record)">
          添加物流单号
          <EditOutlined />
        </a>
        <a v-if="record.state === 'finished'" href="javascript:;" @click="showExpress(record)">
          查看物流详情
          <EyeOutlined />
        </a>
      </template>
      <template v-if="column.dataIndex === 'purchase_time_by'">
        <p> {{ record.purchase_time }} </p>
        <p> {{ record.purchase_by }} </p>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <a-tag v-if="record.state === 'pull'" color="processing">正在同步</a-tag>
        <a-tag v-if="record.state === 'pending'" color="warning">待审核</a-tag>
        <a-tag v-if="record.state === 'purchase'" color="processing">采购中</a-tag>
        <a-tag v-if="record.state === 'finished'" color="success">已完成</a-tag>
        <a-tag v-if="record.state === 'canceled'" color="error">已作废</a-tag>
        <a-tag v-if="record.state === 'abnormal'" color="orange">异常</a-tag>
        <p v-if="record.pay_state === 'pending'">待申请付款</p>
        <p v-if="record.pay_state === 'unpaid'">已申请付款</p>
        <p v-if="record.pay_state === 'paid'">已完成付款</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a-space v-if="record.state === 'pending'">
            <!-- 待审核 可编辑 -->
            <a v-if="record.platform === '1688'" href="javascript:;" @click="createOrderCheck(record._id)">下单</a>
            <!-- 待审核 可标记作废 -->
            <a-popconfirm title="确定要作废采购单?" @confirm="cancelOrder(record._id)">
              <a href="javascript:;" class="text-error">作废</a>
            </a-popconfirm>
          </a-space>
          <template v-if="record.state === 'purchase'">
            <a-popconfirm v-if="record.source === 'offline'" title="确定要作废采购单?" @confirm="cancelOrder(record._id)">
              <a href="javascript:;" class="text-error">作废</a>
            </a-popconfirm>
            <!-- 待申请付款 可申请付款 -->
            <div v-if="record.pay_state === 'pending'">
              <a href="javascript:;" @click="requestPayOrder(record._id)">申请付款</a>
            </div>
            <!-- 已申请付款 可完成付款 -->
            <div v-if="record.pay_state === 'unpaid'">
              <a href="javascript:;" @click="payOrder(record._id)">付款</a>
            </div>
            <!-- 标记完成 -->
            <!-- <div>
              <a href="javascript:;" @click="finishOrder(record._id)">标记完成</a>
            </div> -->
          </template>
          <a-popconfirm v-if="record.state === 'canceled'" title="确定要删除采购单?" @confirm="removeOrder(record._id)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </div>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="expandcolumns" :data-source="record.orderitems" :pagination="false" size="small">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'ruku_time_by'">
            <p>入库时间</p>
            <p>入库人</p>
            <p>入库数量</p>
          </template>
          <template v-if="column.dataIndex === 'purchase_quantity'">
            <p>数量</p>
            <p>未入库数量</p>
          </template>
        </template>
        <template #bodyCell="{ column: icolumn, record: irecord, index: iindex }">
          <template v-if="icolumn.dataIndex === 'index'">
            {{ iindex + 1 }}
          </template>
          <div v-if="icolumn.dataIndex === 'cover'" class="text-center" style="width:50px;">
            <a-tooltip v-if="irecord.product?.cover" placement="rightBottom" color="#fff"
              :overlayStyle="{ width: '500px', height: '500px' }">
              <template #title>
                <img :src="irecord.product?.cover" style="width:500px;height:500px;object-fit: contain;" />
              </template>
              <div class="image-header">
                <img :src="irecord.product?.cover" style="object-fit: contain;" />
              </div>
            </a-tooltip>
            <div v-else class="image-header">
              <img :src="image_placeholder" style="object-fit: contain;" />
            </div>
          </div>
          <template v-if="icolumn.dataIndex === 'info'">
            <a-tooltip color="white">
              <template #title>
                <a href="javascript:;" @click="doCopy(irecord.product?.sku)">复制</a>
              </template>
              <a href="javascript:;">{{ irecord.product?.sku }}</a>
            </a-tooltip>
            <p>{{ irecord.product?.name_cn }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'product_specvalue'">
            <p>{{ irecord.product_specvalue?.join(' ') }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'purchase_quantity'">
            <a-input v-if="irecord.editable" v-model:value="irecord.purchase_quantity" type="number"
              :disabled="updating"></a-input>
            <span v-else>{{ irecord.purchase_quantity }}</span>
            <template v-if="record.state === 'pending'">
              <a-space v-if="irecord.editable">
                <a-button type="link" class="text-success" @click="() => updatePurchaseOrderItemQuantity(irecord)"
                  :loading="updating">保存</a-button>
                <a-button type="link" @click="() => {
                  irecord.editable = false;
                  irecord.purchase_quantity = irecord.old_purchase_quantity;
                }" :disabled="updating">
                  取消
                </a-button>
              </a-space>
              <a-button v-else type="link" danger @click="() => {
                irecord.editable = true;
                irecord.old_purchase_quantity = irecord.purchase_quantity;
              }">
                修改
              </a-button>
            </template>
            <p> {{ irecord.un_ruku_quantity }} </p>
          </template>
          <template v-if="icolumn.dataIndex === 'purchase_price'">
            {{ irecord.purchase_price }}
          </template>
          <template v-if="icolumn.dataIndex === 'ruku_time_by'">
            <p> {{ irecord.inventorylog?.create_time || '-' }}</p>
            <p> {{ irecord.inventorylog?.create_by || '-' }}</p>
            <p> {{ irecord.inventorylog?.quantity || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'operation'">

          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="expressModal" :title="`修改物流信息 采购单号：${expressForm.number}`" @ok="saveExpress"
    @cancel="cancelSaveExpress" ok-text="确认" cancel-text="取消" :confirm-loading="expressSaving"
    :cancel-button-props="{ disabled: expressSaving }" :maskClosable="false" :closable="!expressSaving" :width="800">
    <a-table :columns="expresscolumns" :data-source="expressForm.express_list" :pagination="false" size="small">
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex === 'express_supplier'">
          <a-select v-model:value="record.express_supplier" :options="express_suppliers" :disabled="expressSaving"
            show-search :filter-option="$filters.filterOption" placeholder="物流公司" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'sign_state'">
          <span v-if="record.sign_state" class="text-success"> 已签收 </span>
          <span v-else class="text-error"> 未签收 </span>
        </template>
        <template v-if="column.dataIndex === 'express_no'">
          <a-input v-model:value="record.express_no" placeholder="物流单号" :disabled="expressSaving" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'express_fee'">
          <a-input-group compact>
            <a-input v-model:value="record.express_fee" type="number" placeholder="物流费" suffix="RMB"
              :disabled="expressSaving" style="width:80%;" />
            <div style="width:20%;height:30px;display:inline-flex;align-items:center;">
              <PlusCircleOutlined v-if="index === 0" class="text-info click" style="width:100%;font-size:20px;"
                @click="pushExpress" />
              <MinusCircleOutlined v-else class="text-info click" style="width:100%;font-size:20px;"
                @click="popExpress(index)" />
            </div>
          </a-input-group>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="expressModal2" :title="`查看物流详情 采购单号：${expressForm2.number}`" :width="800" :footer="null">
    <a-table :columns="expresscolumns" :data-source="expressForm2.express_list" :pagination="false" size="small">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'express_supplier'">
          <a-select v-model:value="record.express_supplier" :options="express_suppliers" disabled show-search
            :filter-option="$filters.filterOption" placeholder="物流公司" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'sign_state'">
          <span v-if="record.sign_state" class="text-success"> 已签收 </span>
          <span v-else class="text-error"> 未签收 </span>
        </template>
        <template v-if="column.dataIndex === 'express_no'">
          <a-input v-model:value="record.express_no" placeholder="物流单号" disabled style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'express_fee'">
          <a-input v-model:value="record.express_fee" type="number" placeholder="物流费" suffix="RMB" disabled
            style="width:100%;" />
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-drawer :title="`${purchaseForm._id !== '' ? '编辑' : '新增'}采购单`" placement="right" v-model:visible="purchaseDrawer"
    @close="cancelSavePurchase" width="70%" :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-button class="mr-2" :disabled="saving" @click="cancelSavePurchase">取消</a-button>
      <a-button type="primary" :loading="saving" @click="savePurchase">保存</a-button>
    </template>
    <a-form ref="purchaseRef" name="purchaseForm" :model="purchaseForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-card title="采购单信息" size="small">
            <a-form-item label="采购平台" name="platform" :rules="[{ required: true, message: '选择采购平台' }]">
              <a-select v-model:value="purchaseForm.platform" :options="platforms" :disabled="saving" show-search
                :filter-option="$filters.filterOption" placeholder="选择采购平台" style="width:100%;" />
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :offset="22">
          <a href="javascript:;">
            <span class="underline ml-1" @click="downloadtempfile">采购单上传模板</span>
          </a>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-card size="small">
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>商品信息</span>
                <div>
                  <a-space>
                    <a-upload :before-upload="beforeUpload" :disabled="uploading" :showUploadList="false">
                      <a href="javascript:;">
                        <UploadOutlined />
                        <span class="underline ml-1">批量添加</span>
                      </a>
                    </a-upload>
                    <a href="javascript:;" @click="findsku">
                      <PlusCircleOutlined />
                      <span class="underline ml-1">添加商品</span>
                    </a>
                  </a-space>
                </div>
              </div>
            </template>
            <a-table :columns="purchaseitemcolumns" :data-source="purchaseForm.items" bordered size="small">
              <template #bodyCell="{ record, column, index }">
                <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:50px;">
                  <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
                    :overlayStyle="{ width: '500px', height: '500px' }">
                    <template #title>
                      <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
                    </template>
                    <div class="image-header">
                      <img :src="record.cover" style="object-fit: contain;" />
                    </div>
                  </a-tooltip>
                  <div v-else class="image-header">
                    <img :src="image_placeholder" style="object-fit: contain;" />
                  </div>
                </div>
                <template v-if="column.dataIndex === 'info'">
                  <a-tooltip color="white">
                    <template #title>
                      <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
                    </template>
                    <span class="text-info">{{ record.sku }}</span>
                  </a-tooltip>
                  <p>{{ record.name_cn }}</p>
                </template>
                <template v-if="column.dataIndex === 'product_spec'">
                  <p>{{ record.product_specvalue.join(' ') }}</p>
                </template>
                <template v-if="column.dataIndex === 'quantity'">
                  <a-input v-model:value="record.quantity" type="number" :disabled="saving" placeholder="数量" />
                </template>
                <template v-if="column.dataIndex === 'price'">
                  <a-input v-model:value="record.price" type="number" :disabled="saving" placeholder="单价" />
                </template>
                <template v-if="column.dataIndex === 'operation'">
                  <a href="javascript:;" class="text-error" @click="removeSku(index)">移除</a>
                </template>
              </template>
            </a-table>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
  <a-modal v-model:visible="skuModal" title="查找SKU" :maskClosable="false" :closable="!skuloading" width="960px"
    @cancel="() => skuModal = false">
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="vsku">库存线上SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存线上SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skucolumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ position: ['bottomLeft'], current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }" row-key="sku"
      :row-selection="{ selectedRowKeys: selectedSkus, onChange: (keys) => { selectedSkus = keys; } }" size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:50px;">
          <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header">
              <img :src="record.cover" style="object-fit: contain;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <template v-if="column.dataIndex === 'info'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
            </template>
            <span class="text-info">{{ record.sku }}</span>
          </a-tooltip>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'product_spec'">
          <p>{{ record.product_specvalue.join(' ') }}</p>
        </template>
        <template v-if="column.dataIndex === 'quantity'">
          <a-input v-model:value="record.quantity" type="number" :disabled="skuloading" placeholder="数量" />
        </template>
        <template v-if="column.dataIndex === 'price'">
          <a-input v-model:value="record.price" type="number" :disabled="skuloading" placeholder="单价" />
        </template>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <div>
          <a-checkbox v-model:checked="skuClose">
            添加后直接关闭窗口
          </a-checkbox>
          <a-button @click="addSkus" type="primary" :loading="skuloading">添加</a-button>
          <a-button @click="() => skuModal = false" :disabled="skuloading">关闭</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="buyerModal" title="1688采购下单" :maskClosable="false" :closable="!creating" width="800px"
    @cancel="() => buyerModal = false">
    <a-form ref="buyerRef" name="buyerForm" :model="buyerForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-form-item label="1688账号" name="buyerid" :rules="[{ required: true, message: '选择1688账号' }]">
            <a-select v-model:value="buyerForm.buyerid" :options="buyerlist" :disabled="creating" show-search
              :filter-option="$filters.filterOption" placeholder="选择1688账号" style="width:100%;" @change="changebuyer" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="收货地址" name="addressid" :rules="[{ required: true, message: '选择收货地址' }]">
            <a-select v-model:value="buyerForm.addressid" :options="buyeraddresslist" :disabled="creating" show-search
              :filter-option="$filters.filterOption" placeholder="选择收货地址" style="width:100%;" />
          </a-form-item>
        </a-col>
        <template v-if="preview_result.length > 0">
          <a-col :span="12">
            <a-form-item label="交易方式" name="tradeType" :rules="[{ required: true, message: '选择交易方式' }]">
              <a-select v-model:value="buyerForm.tradeType" :disabled="creating" show-search placeholder="选择交易方式"
                style="width:100%;">
                <a-select-option v-for="item in preview_result[0].tradeModelList" :key="item.tradeType"
                  :value="item.tradeType">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="优惠券" name="shopPromotion">
              <a-select v-model:value="buyerForm.shopPromotionId" :disabled="creating" show-search placeholder="选择优惠券"
                style="width:100%;">
                <a-select-option v-for="item in preview_result[0].shopPromotionList" :key="item.promotionId"
                  :value="item.promotionId">
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <div>
          <p v-if="preview_result[0]">
            预估金额：<span class="text-h6 text-error">{{ preview_result[0].amount }}</span>
          </p>
        </div>
        <a-space>
          <a-button @click="createOrderPreview" type="danger" :disabled="!buyerForm.buyerid || !buyerForm.addressid"
            :loading="previewing">检查优惠</a-button>
          <a-button @click="createOrderConfirm" type="primary" :loading="creating">提交</a-button>
          <a-button @click="() => buyerModal = false" :disabled="creating">关闭</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="payModal" :maskClosable="false" closable centered width="400px">
    <template #title>
      <question-circle-outlined style="color:#FFA505;font-size:1.3rem;" />
      <span class="text-h6 ml-2">支付</span>
    </template>
    <div class="pl-6">
      <p class="font-weight-bold text-h7">请在新打开的页面进行支付。</p>
      <p class="text-gray text-h9">支付完成后，根据您的情况点击下面按钮。</p>
    </div>
    <template #footer>
      <a-space>
        <a-button class="btn-orange" @click="refreshPayResult('success')">支付成功</a-button>
        <a-button class="text-gray" @click="refreshPayResult('fail')">支付失败</a-button>
      </a-space>
    </template>
  </a-modal>
  <a-drawer title="物流轨迹" width="50%" placement="right" v-model:visible="logisticsDrawer"
    @close="() => logisticsDrawer = false">
    <a-list item-layout="horizontal" :data-source="logisticsSteps">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta :description="item.acceptTime">
            <template #title>
              {{ item.remark }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </a-drawer>
  <a-modal v-model:visible="warehouseModal" title="选择仓库" :maskClosable="false" :closable="!saving"
    @cancel="cancelConfirmWarehouse">
    <a-form ref="warehouseRef" name="warehouseForm" :model="warehouseForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-form-item label="入库仓" name="warehouseid" :rules="[{ required: true, message: '选择入库仓' }]">
            <a-select v-model:value="warehouseForm.warehouseid" :options="warehouses" :disabled="saving" show-search
              :filter-option="$filters.filterOption" placeholder="选择入库仓" style="width:100%;" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="cancelConfirmWarehouse" :disabled="saving">关闭</a-button>
          <a-button @click="confirmWarehouse" type="danger" :loading="saving">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined, MinusCircleOutlined, UploadOutlined, DownloadOutlined, EditOutlined, CopyOutlined, LinkOutlined, PlusOutlined, MinusOutlined, ReloadOutlined, QuestionCircleOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";

const columns = [
  { title: '采购单号/下单时间', dataIndex: 'number_createtime', width: 150, },
  { title: '仓库/供应商', dataIndex: 'warehouse_supplier', width: 200, },
  { title: '总数量/总金额', dataIndex: 'quantity_amount', width: 100, },
  { title: '订单信息', dataIndex: 'orderinfo', width: 220, },
  { title: '物流信息', dataIndex: 'express_list', width: 220, },
  { title: '采购时间/采购人', dataIndex: 'purchase_time_by', width: 150, },
  { title: '状态', dataIndex: 'state', width: 120, },
  // { title: '备注', dataIndex: 'purchase_mark', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right', },
];
const expandcolumns = [
  { title: '序号', dataIndex: 'index', width: 60, },
  { title: '图片', dataIndex: 'cover', width: 150, },
  { title: '商品信息', dataIndex: 'info', width: 150, },
  { title: '商品属性', dataIndex: 'product_specvalue', width: 120, },
  { title: '数量', dataIndex: 'purchase_quantity', width: 100, },
  { title: '采购价', dataIndex: 'purchase_price', width: 100, },
  { title: '入库时间/入库人/入库数量', dataIndex: 'ruku_time_by', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 100, },
];
const expresscolumns = [
  { title: '物流公司', dataIndex: 'express_supplier', width: 100, },
  { title: '签收状态', dataIndex: 'sign_state', width: 80, },
  { title: '物流单号', dataIndex: 'express_no', width: 150, },
  { title: '物流费', dataIndex: 'express_fee', width: 120, },
]
const purchaseitemcolumns = [
  { title: '图片', dataIndex: 'cover', width: 80, },
  { title: '商品名称', dataIndex: 'info', width: 120, },
  { title: '商品属性', dataIndex: 'product_spec', width: 120, },
  { title: '供应商', dataIndex: 'supplier', width: 150, },
  { title: '采购数量', dataIndex: 'quantity', width: 100, },
  { title: '采购价', dataIndex: 'price', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100, },
];
const skucolumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info', width: 120, },
  { title: '商品属性', dataIndex: 'product_spec', width: 120, },
  { title: '供应商', dataIndex: 'supplier', width: 120, },
  { title: "采购数量", dataIndex: "quantity", width: 100 },
  { title: "采购价", dataIndex: "price", width: 100 },
];
const { proxy } = getCurrentInstance();
const doCopy = ref(proxy.$utils.doCopy);
const image_placeholder = ref(proxy.$constant.image_placeholder);
const purchaseRef = ref();
const buyerRef = ref();
const warehouseRef = ref();
const daterange = ref(["", ""]);
const searchText = ref("");
const dataLoading = ref(false);
const saving = ref(false);
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const data = ref([]);
const expandedRowKeys = ref([]);
const expanded = ref(false);
const platforms = ref([]);
const warehouses = ref([]);
const suppliers = ref([]);
const initing = ref(false);
const purchaseForm = ref({});
const purchaseDrawer = ref(false);
const purchaseItem = ref({ sku: "", quantity: "", price: "" });
const expressForm = ref({});
const expressModal = ref(false);
const expressSaving = ref(false);
const express_suppliers = ref([]);
const expressForm2 = ref({});
const expressModal2 = ref(false);
const skuModal = ref(false);
const skuForm = ref({ type: "sku", text: "" });
const skupage = ref(1);
const skulimit = ref(100);
const skudata = ref([]);
const skulength = ref(0);
const skucount = ref(0);
const skuloading = ref(false);
const selectedSkus = ref([]);
const skuClose = ref(true);
const buyerlist = ref([]);
const addresslist = ref([]);
const buyeraddresslist = ref([]);
const buyerModal = ref(false);
const buyerForm = ref({});
const previewing = ref(false);
const preview_result = ref([]);
const creating = ref(false);
const payModal = ref(false);
const payForm = ref({});
const logisticsSteps = ref([]);
const logisticsDrawer = ref(false);
const warehouseModal = ref(false);
const warehouseForm = ref({ warehouseid: '' });
const height = ref(600);
const updating = ref(false);
const uploading = ref(false);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  foldall();
  getData();
});
watch(() => limit.value, () => {
  foldall();
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => skupage.value, () => {
  searchSku();
});
watch(() => skulimit.value, () => {
  if (skulimit.value !== 1) skulimit.value = 1;
  else searchSku();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/list", { page: page.value, limit: limit.value, daterange: daterange.value, searchText: searchText.value, }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      express_suppliers.value = res.data.express_suppliers;
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
          purchase_time: n.purchase_time ? proxy.$utils.dateFormat(n.purchase_time, 'YYYY-MM-DD HH:mm:ss') : "",
        }
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
      // expandedRowKeys.value = _.pluck(_.filter(data.value, n => n.orderitems.length > 0), "_id");
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const expandall = () => {
  expandedRowKeys.value = _.pluck(data.value, "_id");
  expanded.value = true;
}
const foldall = () => {
  expandedRowKeys.value = [];
  expanded.value = false;
}
const handleAddOrder = ({ key }) => {
  switch (key) {
    case "1": //采购下单
      addOrder("1688", "online");
      break;
    case "2": //其他采购单
      addOrder("other", "offline");
      break;
  }
}
const addOrder = (platform, source) => {
  initing.value = true;
  proxy.$api.doAPI("/purchaseorder/init", { platform, source }).then((res) => {
    initing.value = false;
    if (res.code === 0) {
      purchaseForm.value = res.data.purchaseForm;
      platforms.value = res.data.platforms;
      warehouses.value = res.data.warehouses;
      suppliers.value = [];
      purchaseItem.value = {
        sku: "", quantity: "", price: "",
      };
      purchaseDrawer.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    initing.value = false;
    console.error(err);
  });
}
//新增采购单-保存
const savePurchase = async () => {
  try {
    await purchaseRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/purchaseorder/save", { purchaseForm: purchaseForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("提交成功");
        purchaseDrawer.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
};
const cancelSavePurchase = () => {
  console.log('cancelSavePurchase');
  purchaseRef.value.clearValidate();
  purchaseDrawer.value = false;
}
const findsku = () => {
  console.log('findsku');
  skuForm.value = { type: "sku", text: "", };
  skupage.value = 1;
  skucount.value = 0;
  skudata.value = [];
  skuModal.value = true;
}
const searchSku = () => {
  // if (!skuForm.value.text) return message.error("请输入搜索内容");
  selectedSkus.value = [];
  skuloading.value = true;
  proxy.$api.doAPI("/product/searchsku", { page: skupage.value, limit: skulimit.value, skuForm: skuForm.value }).then((res) => {
    skuloading.value = false;
    if (res.code === 0) {
      skudata.value = _.map(res.data.list, n => ({ ...n, quantity: 1, price: n.purchase_price || "" }));
      skulength.value = res.data.length;
      skucount.value = res.data.count;
      if (skulength.value > 0 && skupage.value > skulength.value) skupage.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    skuloading.value = false;
    console.error(err);
  });
}
const addSkus = () => {
  if (selectedSkus.value.length === 0) return message.error("请选择要添加的数据");
  let skus = _.filter(skudata.value, n => selectedSkus.value.indexOf(n.sku) > -1);
  console.log(skus);
  for (let i = 0; i < skus.length; i++) {
    let { sku, quantity, price } = skus[i];
    if (!quantity) return message.error(`${sku}采购数量不能为空`);
    if (quantity < 1) return message.error(`${sku}采购数量不能小于1`);
    // if (!price) return message.error(`${sku}采购价不能为空`);
  }
  combineSkus(skus);
  selectedSkus.value = [];
  if (skuClose.value) skuModal.value = false;
}
const combineSkus = (skus) => {
  let items = [], combine_skus = {};
  for (let i = 0; i < purchaseForm.value.items.length; i++) {
    let { sku } = purchaseForm.value.items[i];
    if (!combine_skus[sku]) {
      items.push(_.extend({}, purchaseForm.value.items[i]));
      combine_skus[sku] = true;
    }
  }
  for (let i = 0; i < skus.length; i++) {
    let { sku, quantity, price, supplier, product_specid, product_specvalue } = skus[i];
    if (!combine_skus[sku]) {
      items.push(_.extend({ quantity, price, supplier, product_specid, product_specvalue }, skus[i]));
      combine_skus[sku] = true;
    }
  }
  purchaseForm.value.items = items;
}
const removeSku = (index) => {
  purchaseForm.value.items.splice(index, 1);
}
const choosewarehouse = (item) => {
  warehouseForm.value = {
    purchaseorderid: item._id,
    warehouseid: item.warehouseid || undefined,
  };
  proxy.$api.doAPI("/purchaseorder/init").then((res) => {
    if (res.code === 0) {
      warehouses.value = res.data.warehouses;
      warehouseModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    console.error(err);
  });
}
const confirmWarehouse = async () => {
  try {
    await warehouseRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/purchaseorder/savewarehouse", { warehouseForm: warehouseForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        warehouseModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
};
const cancelConfirmWarehouse = () => {
  console.log('cancelConfirmWarehouse');
  warehouseRef.value.clearValidate();
  warehouseModal.value = false;
}
const addExpress = (item) => {
  let expressForm1 = {
    _id: item._id,
    number: item.number,
    express_list: _.map(item.express_list, n => _.extend({}, { ...n })),
  }
  if (expressForm1.express_list.length === 0) {
    expressForm1.express_list.push({
      key: proxy.$utils.uuid(),
      express_supplier: null,
      express_no: "",
      express_fee: item.postFee || "",
      sign_state: false,
    });
  } else {
    expressForm1.express_list = _.map(expressForm1.express_list, n => {
      return {
        key: proxy.$utils.uuid(),
        express_supplier: n.express_supplier,
        express_no: n.express_no,
        express_fee: n.express_fee,
        sign_state: n.sign_state,
      }
    });
  }
  expressForm.value = expressForm1;
  expressModal.value = true;
}
const showExpress = (item) => {
  let expressForm = {
    _id: item._id,
    number: item.number,
    express_list: _.map(item.express_list, n => _.extend({}, { ...n })),
  }
  if (expressForm.express_list.length > 0) {
    expressForm.express_list = _.map(expressForm.express_list, n => {
      return {
        key: proxy.$utils.uuid(),
        express_supplier: n.express_supplier,
        express_no: n.express_no,
        express_fee: n.express_fee,
        sign_state: n.sign_state,
      }
    });
  }
  expressForm2.value = expressForm;
  expressModal2.value = true;
}
const pushExpress = () => {
  expressForm.value.express_list.push({
    key: proxy.$utils.uuid(),
    express_supplier: "",
    express_no: "",
    express_fee: "",
    sign_state: false,
  });
}
const popExpress = (index) => {
  expressForm.value.express_list.splice(index, 1);
}
const saveExpress = () => {
  if (expressForm.value.express_list.length === 0) return message.error('物流信息不能为空');
  for (let i = 0; i < expressForm.value.express_list.length; i++) {
    let { express_supplier, express_no, express_fee } = expressForm.value.express_list[i];
    if (!express_supplier) return message.error('物流公司不能为空');
    if (!express_no) return message.error('物流单号不能为空');
  }
  expressSaving.value = true;
  proxy.$api.doAPI("/purchaseorder/saveexpress", { expressForm: expressForm.value, }).then((res) => {
    expressSaving.value = false;
    if (res.code === 0) {
      message.success("保存成功");
      expressModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    expressSaving.value = false;
    console.error(err);
  });
}
const cancelSaveExpress = () => {
  console.log('cancelSaveExpress');
}
const showLogisticsTrackInfo = (expressid) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/trackinfo", { expressid }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      logisticsSteps.value = res.data.logisticsSteps.reverse();
      logisticsDrawer.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const createOrderCheck = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/create_check", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      buyerlist.value = res.data.buyerlist;
      addresslist.value = res.data.addresslist;
      buyeraddresslist.value = [];
      buyerForm.value = {
        purchaseorderid: _id,
        buyerid: undefined,
        addressid: undefined,
      };
      preview_result.value = [];
      buyerModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const changebuyer = (buyerid) => {
  console.log('changebuyer', buyerid);
  buyerForm.value.addressid = undefined;
  buyerForm.value.tradeType = undefined;
  buyerForm.value.shopPromotionId = undefined;
  preview_result.value = [];
  buyeraddresslist.value = _.map(_.where(addresslist.value, { buyerid }), n => _.extend({}, { ...n }));
}
const createOrderPreview = () => {
  previewing.value = true;
  proxy.$api.doAPI("/purchaseorder/create_preview", { buyerForm: buyerForm.value, }).then((res) => {
    previewing.value = false;
    if (res.code === 0) {
      preview_result.value = res.data.preview_result;
      if (res.data.preview_result.length === 0) return message.info("无优惠");
      buyerForm.value.flow = res.data.preview_result[0].flow;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    previewing.value = false;
    console.error(err);
  });
}
const createOrderConfirm = async () => {
  try {
    await buyerRef.value.validateFields();
    creating.value = true;
    proxy.$api.doAPI("/purchaseorder/create_confirm", { buyerForm: buyerForm.value, }).then((res) => {
      creating.value = false;
      if (res.code === 0) {
        message.success("提交成功");
        buyerModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      creating.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
};
const refreshOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/refresh", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const requestPayOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/requestpay", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const payOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/pay", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      payForm.value = {
        purchaseorderid: _id,
        payUrl: res.data.payUrl,
      };
      proxy.$utils.downloadBrowser({ href: res.data.payUrl });
      payModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const refreshPayResult = () => {
  payModal.value = false;
  refreshOrder(payForm.value.purchaseorderid);
}
const cancelOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/cancel", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const removeOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/remove", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const finishOrder = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/finish", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const updatePurchaseOrderItemQuantity = (purchaseorderitem) => {
  console.log('updatePurchaseOrderItemQuantity', purchaseorderitem);
  updating.value = true;
  proxy.$api.doAPI("/purchaseorder/updateitemquantity", purchaseorderitem).then((res) => {
    updating.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    updating.value = false;
    console.error(err);
  });
}
const downloadtempfile = () => {
  let a = document.createElement("a");
  a.href = `${process.env.BASE_URL}template/采购单上传模板1.xlsx`;
  a.download = "采购单上传模板.xlsx";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  a.remove();
};
const beforeUpload = file => {
  console.log('beforeUpload');
  let checkresult = checkfile(file);
  if (checkresult.code != 0) return message.error(checkresult.msg);
  doUploadExcel(file);
};
const checkfile = (file) => {
  const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isXLSX) {
    return { code: 1, msg: "只允许上传XLSX文件" };
  } else if (!isLt5M) {
    return { code: 1, msg: "单个文件不能超过5MB" };
  }
  return { code: 0 };
}
const doUploadExcel = async (file) => {
  uploading.value = true;
  const slicefiles = proxy.$utils.sliceFile(file.size);
  const faid = proxy.$utils.uuid();
  for (let i = 0; i < slicefiles.length; i++) {
    let slicefile = slicefiles[i];
    const formData = new FormData();
    if (slicefile.chunks > 1) {
      let _file = file.slice(slicefile.from, slicefile.to);
      formData.set("file", _file);
    } else {
      formData.set("file", file);
    }
    formData.set("faid", faid);
    formData.set("chunk", slicefile.chunk);
    formData.set("chunks", slicefile.chunks);
    formData.set("originalname", file.name);
    try {
      let res = await proxy.$api.doUpload("/purchaseorder/plupload", formData);
      if (res.code === 0) {
        if (slicefile.chunk + 1 === slicefile.chunks) {
          purchaseForm.value.items = res.data;
        }
      } else {
        message.error(res.msg);
        break;
      }
    } catch (e) {
      console.error(e);
      message.error(e.message);
      break;
    }
  }
  console.log('doUploadExcel-finished');
  uploading.value = false;
}
const searchSupplier2 = (item) => {
  let url = `https://s.1688.com/company/company_search.htm?keywords=${proxy.$utils.urlencode.encode(item.product_supplier.name, 'gbk')}`;
  proxy.$utils.downloadBrowser({ href: url });
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.editable-cell {
  position: relative;

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    margin-top: -30px;
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>